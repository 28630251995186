import dayjs from 'dayjs';
import { IResponse, UploadStatus } from '@/app/shared/components/kl-upload/kl-upload-geometry/kl-upload-geometry';
import {computed, defineComponent, reactive, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {
    IGeometry
} from '@/app/settings/components/kl-organisation-settings/kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-create-edit-zone/components/kl-create-geometry-on-map/kl-create-geometry-on-map';
import { useErrorStore } from '@/stores/error-store';

export default defineComponent({
    name: 'KlCreateGeometry',
    emits: ['revert', 'geometry-update', 'geometry-upload-finished'],
    props: {
        modRevertable: { type: Boolean, default: false },
        uploadUrl: { type: String, default: '' },
    },
    setup(props, { emit }) {

        const geometryActivationMinDate = dayjs().add(1, 'day').format('DD-MM-YYYY');
        const geometryLastModificationMaxDate = dayjs().format('DD-MM-YYYY');

        const steps = reactive<IGeometry>({
            activation: {
                label: 'Activering geometrie',
                fields: { geometryActivationDate: null },
            },
            reference: {
                label: 'Uw referenties',
                fields: {
                    geometryVersion: null,
                    geometryPrecision: null,
                    geometryLastModificationDate: null,
                    geometryReference: null,
                },
            },
            file: {
                label: 'Laad uw bestand op',
                shortLabel: 'Opladen',
                fields: {
                    geometryBlobName: null,
                },
            },
        });

        const activeStepIndex = ref<number>(1);
        const response = ref<IResponse>(null);
        const uploading = ref<boolean>(false);

        const stepOrder = computed(() => {
            return [steps.activation, steps.reference, steps.file];
        });

        const activeStep = computed(() => {
            return stepOrder.value[activeStepIndex.value - 1];
        });

        const buttonVisible = computed(() => {
            return activeStepIndex.value !== 3;
        });

        const geometryFields = computed(() => {
            return {
                activation: steps.activation.fields,
                reference: steps.reference.fields,
                file: steps.file.fields,
            };
        });


        const uploadStatus = (geometryBlobName: string) => {
            if (uploading.value) {
                return;
            }

            uploading.value = true;
            useErrorStore().setBypassError(['400']);
            useKlipApiProxy().unaSettings_GetKlipZoneFileStatus(geometryBlobName)
                .then((uploadStatusResponse) => {
                    geometryFields.value.file.geometryBlobName = geometryBlobName;
                    emit('geometry-update', geometryFields.value);

                    response.value = {
                        downloadUrls: uploadStatusResponse.result.downloadUrls,
                        status: UploadStatus.success,
                        errorMessages: uploadStatusResponse.result.validationErrors,
                    };

                    const geometry = {
                        blobName: geometryBlobName,
                        activationDate: geometryFields.value.activation.geometryActivationDate
                            ? geometryFields.value.activation.geometryActivationDate[0]
                            : null,
                        lastModificationDate: geometryFields.value.reference.geometryLastModificationDate
                            ? geometryFields.value.reference.geometryLastModificationDate[0]
                            : null,
                        precision: geometryFields.value.reference.geometryPrecision,
                        reference: geometryFields.value.reference.geometryReference,
                        version: geometryFields.value.reference.geometryVersion,
                        downloadUrls: uploadStatusResponse.result.downloadUrls,
                        isActive: false,
                        isDeleted: false,
                    };
                    emit('geometry-upload-finished', geometry);
                }).catch((errorResponse) => {
                    response.value = {
                        downloadUrls: null,
                        status: UploadStatus.error,
                        errorMessages: errorResponse?.result?.validationErrors,
                    };
                }).finally(() => {
                    useErrorStore().resetBypassError();
                    uploading.value = false;
                });
        }

        const changeStep = (newStepIndex: number) => {
            activeStepIndex.value = newStepIndex;
            if (activeStepIndex.value === 3) {
                emit('geometry-update', geometryFields.value);
            }
        }

        const nextStep = () => {
            changeStep(activeStepIndex.value + 1);
        }

        const revert = () => {
            emit('revert');
        }


        return {
            geometryActivationMinDate,
            geometryLastModificationMaxDate,
            steps,
            activeStepIndex,
            response,
            uploading,
            stepOrder,
            activeStep,
            buttonVisible,
            geometryFields,

            uploadStatus,
            changeStep,
            nextStep,
            revert,
        }
    }
})
