import { IApplicationMessages, IAlertNotification } from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import { CodeLists } from '../../state/CodeListModule';
import { UserData } from '@/app/shared/state/UserDataModule';
import dayjs from 'dayjs';
import {computed, defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';
import {useSignalrHub} from '@/plugins/signalr';
import {useKlipApiProxy} from '@/plugins/proxy-client';

export default defineComponent({
    name: 'KlApplicationMessages',
    props: {},
    setup(props) {

        const route = useRoute();
        const router = useRouter();
        const root = getCurrentInstance().proxy.$root;

        const sessionTimeoutLocalStorageKey: string = 'sessiontimeout_redirect';

        const applicationMessages = ref<IApplicationMessages>(null);
        const isSessionTimeoutVisibleValue = ref<boolean>(false);


        const acmAuthentication = computed((): boolean => {
            return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
        });

        const isBeta = computed(() => {
            return CodeLists.isBeta;
        });

        const betaMessage = computed((): IAlertNotification => {
            return {
                title: 'Let op! Dit is een test omgeving',
                message: 'Deze omgeving gebruik je enkel om te testen. Een echte planaanvraag doe je op <a href="https://klip.vlaanderen.be">klip.vlaanderen.be</a>',
                isClosable: false,
            };
        });

        const messages = computed((): IAlertNotification[] => {
            if (applicationMessages.value && applicationMessages.value.messages) {
                return applicationMessages.value.messages;
            }
            return [];
        });

        const whatsNew = computed(() => {
            if (applicationMessages.value) {
                return applicationMessages.value.whatsNew;
            }
            return null;
        });

        const isSessionTimeoutVisible = computed(() => {
            return isSessionTimeoutVisibleValue.value;
        });


        const closeMessage = (id: string) => {
            userLocalStorage.appendItemToArray('application_announcements', id);
            _removeMessage(id);
        }

        const login = () => {
            useSignalrHub().stop(`${window.location.origin}/auth/login?returnUrl=${window.location.pathname}`);
        }


        const _getapplicationMessages = () => {
            useKlipApiProxy().messages_GetApplicationMessages().then((response) => {
                applicationMessages.value = response.result;
                if (!applicationMessages.value.messages && !applicationMessages.value.whatsNew) {
                    return;
                }

                const removedMessageIds: string[] = userLocalStorage.getItem('application_announcements', true) as string[];
                if (!removedMessageIds) {
                    return;
                }

                if (applicationMessages.value.messages) {
                    applicationMessages.value.messages = applicationMessages.value.messages.filter((message) => !removedMessageIds.includes(message.id));
                }

                if (applicationMessages.value.whatsNew && removedMessageIds.includes(applicationMessages.value.whatsNew.id)) {
                    applicationMessages.value.whatsNew = null;
                }
            });
        }

        const _initApplicationMessagePolling = () => {
            if (!isSessionTimeoutVisibleValue.value) {
                setTimeout(() => {
                    _getapplicationMessages();
                    _initApplicationMessagePolling();
                }, 300000);
            }
        }

        const _initSessionTimeoutPolling = () => {
            setTimeout(() => {
                if (dayjs(UserData.user.sessionTimeout) < dayjs()) {
                    userLocalStorage.setItem(sessionTimeoutLocalStorageKey, router.currentRoute.path);
                    // Explicit call to disconnect from signalr
                    useSignalrHub().stop();
                    isSessionTimeoutVisibleValue.value = true;
                } else {
                    _initSessionTimeoutPolling();
                }
            }, 10000);
        }

        const _removeMessage = (id: string) => {
            applicationMessages.value.messages = applicationMessages.value.messages.filter((message) => message.id !== id);
        }


        onMounted(() => {
            _getapplicationMessages();
            _initApplicationMessagePolling();
            _initSessionTimeoutPolling();
            root.$on('closed', () => {
                if (applicationMessages.value.whatsNew) {
                    userLocalStorage.appendItemToArray('application_announcements', applicationMessages.value.whatsNew.id);
                }
            });
        });


        return {
            applicationMessages,
            acmAuthentication,
            isBeta,
            betaMessage,
            messages,
            whatsNew,
            isSessionTimeoutVisible,
            closeMessage,
            login,
        }
    }
})
