import { UserData } from '@/app/shared/state/UserDataModule';
import { CodeLists } from '@/app/shared/state/CodeListModule';
import { mriInvoices } from '@/app/mri/mri-routes';
import { settings } from '@/app/settings/settings-routes';
import { klipIndex } from '@/router/router';
import { Prepaid } from '@/app/prepaid/prepaid-routes';
import { PaymentTypes, LogEventLevel, LogImpact, LogEntry } from '@/api/klip-api.proxy';
import {computed, defineComponent, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/plugins/routes';
import {useSignalrHub} from '@/plugins/signalr';

export default defineComponent({
    name: 'KlProfileDropdown',
    props: {},
    setup(props) {

        const router = useRouter();

        const isOpen = ref<boolean>(false);
        const toggleFocus = ref<boolean>(false);
        const prepaidSaldo = ref<number>(null);
        const isFinancialServiceAvailable = ref<boolean>(true);

        const isOnlyPdaManager = computed<boolean>(() => {
            if (user.value.roles.length > 1) {
                return false;
            }
           return userData.value.hasRole(['pda_manager']);
        });

        const user = computed(() => {
            return userData.value.user;
        });

        const financialServiceAvailable = computed(() => {
            return isFinancialServiceAvailable.value;
        });

        const organisationName = computed(() => {
            let name = user.value.organisation;
            if (userData.value.hasRole(['una_manager', 'una_operator', 'una_reader']) &&
                user.value.displayName
            ) {
                name = user.value.displayName;
            }
            return name;
        });

        const userData = computed(() => {
            return UserData;
        });

        const routeMriInvoices = computed(() => {
            return mriInvoices;
        });

        const routeSettings = computed(() => {
            return settings;
        });

        const routeKlipIndex = computed(() => {
            return klipIndex;
        });

        const routePrepaid = computed(() => {
            return Prepaid;
        });

        const acmAuthentication = computed((): boolean => {
            return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
        });

        const userScope = computed(() => {
            if(userData.value.scopes.length === 0 && userData.value.hasRole(['org_manager'])) {
                return {
                    key: 'org',
                    abbr: 'ORG',
                    label: 'Organisatie Beheerder'
                }
            }
            return userData.value.scopes.find((scope) => userData.value.scope === scope.key);
        });

        const userHasMultipleRoles = computed(() => {
            return Object.keys(userData.value.scopes).length > 1;
        });

        const showRolepicker = computed(() => {
            return userHasMultipleRoles.value;
        });

        const rolePickerItems = computed(() => {
            return user.value.roles.map((role) => {
                return {
                    key: role,
                    label: CodeLists.codeLists ? CodeLists.codeLists.userRoleCodes[role] : role,
                };
            });
        });

        const showSettings = computed(() => {
            //hide settings if the user has only the map request reader role.
            if (user.value.roles.length > 1) {
                return true;
            }
            return !userData.value.hasRole(['mrr']);
        });

        const applyMinWidth = computed(() => {
            return !showRolepicker.value;
        });

        const showBalance = computed(() => {
            return UserData.user.paymentType !== PaymentTypes.Invoice && !UserData.user.isCitizen && UserData.scope === 'mri' && userData.value.hasRole(['mri']);
        });

        const hasPrepaidSaldo = computed(() => {
            return prepaidSaldo.value != null;
        });


        const close = () => {
            isOpen.value = false;
        }

        const toggle = () => {
            isOpen.value = !isOpen.value;
            if (isOpen.value && showBalance.value) {
                useKlipApiProxy().payment_GetPrepaidAmount()
                    .then((response) => {
                        isFinancialServiceAvailable.value = response.result.financialServiceAvailable;
                        prepaidSaldo.value = response.result.amount;
                    });
            }
        }

        const switchScope = (scope: string) => {
            userData.value.setScope(scope);
            close();
            router.push('/' + scope);
        }

        const logout = () => {
            if (acmAuthentication.value) {
                useSignalrHub().stop(`${window.location.origin}/auth/logout`);
            } else {
                useSignalrHub().stop(`${window.location.origin}/geosecure_auth/logout`);
            }
        }


        return {
            isOpen,
            toggleFocus,
            prepaidSaldo,
            isOnlyPdaManager,
            user,
            organisationName,
            userData,
            routeMriInvoices,
            routeSettings,
            routeKlipIndex,
            routePrepaid,
            acmAuthentication,
            userScope,
            userHasMultipleRoles,
            showRolepicker,
            rolePickerItems,
            showSettings,
            applyMinWidth,
            showBalance,
            hasPrepaidSaldo,
            financialServiceAvailable,
            close,
            toggle,
            switchScope,
            logout,
        }
    }
})
