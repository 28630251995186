import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';

export const mriIndex: KlRouteConfig = {
    path: '/mri',
    name: 'mri',
    component: () => import(/* webpackChunkName: "mri" */ './index.vue'),
    meta: {
        authorize: { roles: ['mri', 'mrr'] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const mriOverlayCreate: KlRouteConfig = {
    path: '/mri/overlay/create',
    name: 'mrioverlaycreate',
    component: () => import(/* webpackChunkName: "mri" */ './overlay/create.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Nieuwe opvraging aanwezige leidingbeheerders', parent: mriIndex },
    },
};


export const mriMapRequestCreate: KlRouteConfig = {
    path: '/mri/maprequest/create',
    name: 'mrimaprequestcreate',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Nieuwe planaanvraag', parent: mriIndex },
    },
};

export const mriMapRequestResubmit: KlRouteConfig = {
    path: '/mri/maprequest/resubmit/:id/:archived',
    name: 'mrimaprequestresubmit',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Nieuwe planaanvraag', parent: mriIndex },
    },
};

export const mriMapRequestSubmitOverlay: KlRouteConfig = {
    path: '/mri/maprequest/submitoverlay/:id',
    name: 'mrimaprequestsubmitoverlay',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Nieuwe planaanvraag', parent: mriIndex },
    },
};

export const mriMapRequestShoppingCart: KlRouteConfig = {
    path: '/mri/shoppingcart/maprequest/:id/',
    name: 'mrimaprequestshoppingcart',
    component: () => import(/* webpackChunkName: "mri" */ './create.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Planaanvraag aanpassen', parent: mriIndex },
    },
};

export const mriMapRequest: KlRouteConfig = {
    path: '/mri/maprequest/:id/map',
    name: 'mrimaprequest',
    component: () => import(/* webpackChunkName: "mri" */ './map-request.vue'),
    meta: {
        authorize: { roles: ['mri', 'mrr', 'helpdesk'] },
        breadcrumb: { title: 'Planaanvraag', parent: mriIndex },
    },
};

export const mriOverlayDetail: KlRouteConfig = {
    path: '/mri/overlay/:id',
    name: 'mrioverlaydetail',
    component: () => import(/* webpackChunkName: "mri" */ './overlay/id.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Opvraging', parent: mriIndex },
    },
};

export const mriShoppingCart: KlRouteConfig = {
    path: '/mri/shoppingcart',
    name: 'mrishoppingcart',
    component: () => import('./shopping-cart/index.vue'),
    meta: {
        authorize: { roles: ['mri'] },
        breadcrumb: { title: 'Winkelmandje', parent: klipIndex },
    },
};

export const mriInvoices: KlRouteConfig = {
    path: '/mri/invoices',
    name: 'mriinvoices',
    component: () => import('./invoices/index.vue'),
    meta: {
        authorize: { roles: ['mri', 'mrr'] },
        breadcrumb: { title: 'Facturen', parent: klipIndex },
    },
};

export const mriViewer: KlRouteConfig = {
    path: '/mri/viewer/:mapRequestId',
    name: 'mriviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        authorize: { roles: ['mri', 'mrr', 'helpdesk', 'admin'] },
        breadcrumb: { title: 'Leidingviewer', parent: mriMapRequest },
    },
};

export const mriInvoicesForContact: KlRouteConfig = {
    path: '/mri/invoices/:userId',
    props: true,
    name: 'invoicesforcontact',
    component: () => import('./invoices/index.vue'),
    meta: {
        authorize: { roles: ['helpdesk', 'admin'] },
        breadcrumb: { title: 'Facturen en betalingsbewijzen', parent: klipIndex },
    },
};

export default [
    mriIndex,
    mriMapRequestCreate,
    mriMapRequest,
    mriOverlayCreate,
    mriMapRequestResubmit,
    mriMapRequestShoppingCart,
    mriMapRequestSubmitOverlay,
    mriOverlayDetail,
    mriInvoices,
    mriInvoicesForContact,
    mriViewer,
    mriShoppingCart,
];
