import { Geometry } from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import DateUtil from '@/app/shared/helpers/date-util';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'KlGeometryDetailsOnMap',
    emits: ['remove-geometry'],
    props: {
        geometry: { type: Object as () => Geometry, required: true },
        allGeometries: { type: Array as () => Geometry[], required: false, default: null },
        showRemoveButton: { type: Boolean, required: false, default: false },
    },
    setup(props, { emit }) {

        const downloadPreferenceLocalStorageKey: string = 'geometry_download_preference';
        const downloadFiletype = ref<string>('GeoJson');

        const downloadFileUri = computed(() => {
            return props.geometry.downloadUrls && downloadFiletype.value ?
                props.geometry.downloadUrls[downloadFiletype.value] : null;
        });

        const showDescriptionData = computed(() => {
            return !!(props.geometry.version || props.geometry.lastModificationDate || props.geometry.precision);
        });

        const title = computed(() => {
            return `${props.geometry.reference ? `'${props.geometry.reference}'` : ''}${props.geometry.isActive ?
                `${props.geometry.reference ? ` - ` : ``}actief sinds ${date(props.geometry.activationDate)}` :
                ` wordt geactiveerd op ${date(props.geometry.activationDate)}`}`;
        });

        const _nextGeometryToBeActivated = computed((): Geometry => {
            let next: Geometry = null;
            props.allGeometries?.forEach((cg: Geometry) => {
                if (cg.isActive) {
                    return;
                }
                if (!next) {
                    next = cg;
                    return;
                }
                const activationDate = dayjs(cg.activationDate);
                const nextActivationDate = dayjs(next.activationDate);
                if (activationDate.isBefore(nextActivationDate)) {
                    next = cg;
                }
            });

            return next;
        });

        const drawZones = computed((): IDrawZoneMapGeometry[] => {

            const subtitle = props.geometry.isActive
                ? `Actief sinds ${date(props.geometry.activationDate)}`
                : `Wordt geactiveerd op ${date(props.geometry.activationDate)}`;

            let subtitle2 = '';
            const nextGeometryToBeActivated = _nextGeometryToBeActivated.value;
            if (!!props.geometry.isActive && nextGeometryToBeActivated) {
                const archivationDate = DateUtil.formatDate(nextGeometryToBeActivated.activationDate);
                const nextGeometryReference = nextGeometryToBeActivated.reference;
                const nextGeometryReferenceLabel = nextGeometryReference ? ` als '${nextGeometryReference}' actief wordt` : '';
                subtitle2 = `Wordt gearchiveerd op ${archivationDate}${nextGeometryReferenceLabel}.`;
            }

            return [{
                wkt: props.geometry.wkt,
                geometryInfo: {
                    title: props.geometry.reference,
                    isActive: !!props.geometry.isActive,
                    subtitle: subtitle,
                    subtitle2: subtitle2,
                    version: props.geometry.version, // as generic properties (label + value)?
                    lastModificationDate: props.geometry.lastModificationDate ? DateUtil.formatDate(props.geometry.lastModificationDate) : null, // as generic properties (label + value)?
                    precision: props.geometry.precision ? props.geometry.precision + 'm' : null, // as generic properties (label + value)?
                    downloadUrls: props.geometry.downloadUrls,
                }
            }];
        });

        const changeSelect = () => {
            userLocalStorage.setItem(downloadPreferenceLocalStorageKey, downloadFiletype.value);
        }

        const date = (date: string) => {
            return DateUtil.formatDate(date);
        }

        const onRemove = () => {
            emit('remove-geometry', props.geometry);
        }

        onMounted(() => {
            downloadFiletype.value = userLocalStorage.getItem(downloadPreferenceLocalStorageKey) || 'GeoJson';
        })


        return {
            downloadFiletype,
            downloadFileUri,
            showDescriptionData,
            title,

            changeSelect,
            date,
            onRemove,

            drawZones,
        }
    }
})
