import { defineComponent, ref } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {EFeatureToggle, useFeatureToggleStore} from '@/stores/feature-toggle-store';

export default defineComponent({
    props: {
        zone: {
            type: KlipApi.UnaZoneEditView,
            default: null,
            required: true
        }
    },
    setup: function (props) {
        const unaZone = ref<KlipApi.UnaZoneEditView>(props.zone);
        const useDrawZoneComponent = ref(useFeatureToggleStore().getFeatureToggle(EFeatureToggle.VITE_FEATURE_ENABLE_ORG_DRAW_ZONES));


        return{
            unaZone,
            useDrawZoneComponent,
        }
    }
});
