import { Geometry } from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import DateUtil from '@/app/shared/helpers/date-util';
import {computed, defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
    name: 'KlGeometryDetails',
    emits: ['geometry-before-delete'],
    props: {
        geometry: { type: Object as () => Geometry, required: true },
        showDeleteButton: { type: Boolean, required: false, default: false },
    },
    setup(props, { emit }) {

        const downloadPreferenceLocalStorageKey: string = 'geometry_download_preference';
        const downloadFiletype = ref<string>('GeoJson');

        const downloadFileUri = computed(() => {
            return props.geometry.downloadUrls && downloadFiletype.value ?
                props.geometry.downloadUrls[downloadFiletype.value] : null;
        });

        const showDescriptionData = computed(() => {
            return !!(props.geometry.version || props.geometry.lastModificationDate || props.geometry.precision);
        });

        const title = computed(() => {
            return `${props.geometry.reference ? `'${props.geometry.reference}'` : ''}${props.geometry.isActive ?
                `${props.geometry.reference ? ` - ` : ``}actief sinds ${date(props.geometry.activationDate)}` :
                ` wordt geactiveerd op ${date(props.geometry.activationDate)}`}`;
        });


        const changeSelect = () => {
            userLocalStorage.setItem(downloadPreferenceLocalStorageKey, downloadFiletype.value);
        }

        const date = (date: string) => {
            return DateUtil.formatDate(date);
        }

        const onDelete = () => {
            emit('geometry-before-delete', props.geometry);
        }

        onMounted(() => {
            downloadFiletype.value = userLocalStorage.getItem(downloadPreferenceLocalStorageKey) || 'GeoJson';
        })


        return {
            downloadFiletype,
            downloadFileUri,
            showDescriptionData,
            title,
            changeSelect,
            date,
            onDelete,
        }
    }
})
