import {computed, defineComponent, ref, watch} from 'vue';

export default defineComponent({
    name: 'KlAutocomplete',
    emits: ['select-option'],
    inheritAttrs: false,
    props: {
        data: {
            type: Array as () => AutoCompleteResult[],
            required: true,
        },
        fetching: { type: Boolean, default: false },
        helpText: { type: String, required: false, default: 'Gebruik de pijltjes (boven en onder) om, na het invoeren van X karakters, door de suggesties van de suggestiebox te navigeren. Bevestig uw keuze met &quot;enter&quot; of gebruik de &quot;escape&quot; knop om te suggestiebox te sluiten.' },
        modRequired: { type: Boolean, default: false, required: false },
    },
    setup(props, { emit }) {

        const inputField = ref(null);

        const results = ref<AutoCompleteResult[]>([]);
        const resultsVisible = ref<boolean>(true);

        const hasResults = computed(() => {
            return results.value.length;
        });


        const _reset = () => {
            results.value = [];
        }

        const hideResults = () => {
            resultsVisible.value = false;
        }

        const showResults = () => {
            resultsVisible.value = true;
        }

        const _navigateResults = (direction: string) => {
            if (results.value.length) {
                const currentResult = results.value.findIndex((result) => result.selected);
                const newResult = direction === 'down' ? currentResult === (results.value.length - 1) ? 0 : currentResult + 1 :
                    !currentResult || currentResult === 0 ? (results.value.length - 1) : currentResult - 1;
                results.value = results.value.map((obj) => ({ ...obj, selected: false }));
                results.value[newResult].selected = true;
            }
        }

        const arrowDown = () => {
            if (results.value && !resultsVisible.value) {
                resultsVisible.value = true;
            } else {
                _navigateResults('down');
            }
        }

        const arrowUp = () => {
            _navigateResults('up');
        }

        const selectFocussedResult = () => {
            console.log('selectFocussedResult');
            const focussedResult = results.value.filter((obj) => obj.selected);
            if (results.value && resultsVisible.value && focussedResult[0]) {
                select(focussedResult[0]);
            }
        }

        const _isAutoCompleteResult = (result: AutoCompleteResult): result is AutoCompleteResult => {
            return result.value !== undefined;
        }

        const select = (item: AutoCompleteResult) => {
            if (_isAutoCompleteResult(item)) {
                emit('select-option', item);
                _reset();
            }
        }

        const focus = () => {
            // TODO: review after WebComponent migrate to vue3
            // Is there a better way to focus an element?
            (inputField.value.$el as HTMLElement).focus();
        }


        watch(
            () => props.data,
            () => {
                results.value = props.data.map((obj) => ({ ...obj, selected: false }));
            },
            { immediate: true, deep: true });

        watch(
            results,
            () => {
                if (hasResults.value) {
                    showResults();
                }
            },
            { immediate: true, deep: true });


        return {
            inputField,

            results,
            resultsVisible,
            hasResults,
            hideResults,
            showResults,
            arrowDown,
            arrowUp,
            selectFocussedResult,
            select,
            focus,
        }
    }
})
