import {UserData} from "../../state/UserDataModule";
import {ShoppingCart} from "../../state/ShoppingCartModule";
import {CodeLists} from "../../state/CodeListModule";
import {ProcessingTopUp, ProcessingShoppingCart} from "@/app/checkout/checkout-routes";
import { useErrorStore } from "../../../../stores/error-store";
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';

export default defineComponent({
    name: 'FunctionalHeader',
    props: {},
    setup(props) {

        const route = useRoute();
        const router = useRouter();

        const hasErrors = computed((): boolean => {
            return useErrorStore().hasErrors || (!!UserData.user && (UserData.user.roleError || !!UserData.user.validationError));
        });

        const isUpdating = computed((): boolean => {
            return UserData.isUpdating;
        });

        const loginLink = computed((): string => {
            const useAcmAuthentication = import.meta.env.VITE_ACM_AUTHENTICATION === "true";
            return useAcmAuthentication ? "auth/login" : "geosecure_auth/login";
        });

        const isLoggedOn = computed((): boolean => {
            return UserData.loggedOn;
        });

        const isAdmin = computed((): boolean => {
            return UserData.hasRole(["admin", "helpdesk"]);
        });

        const showAdminNavigation = computed((): boolean => {
            return isAdmin.value && route.path.startsWith("/admin");
        });

        const numberItemsInCart = computed((): number => {
            if (!isLoggedOn.value) {
                return 0;
            }
            return ShoppingCart.numberOfItems;
        });

        const functionalHeaderClassName = computed((): string => {
            const classList = ["kl-functional-header"];

            if (CodeLists.isBeta) {
                classList.push("beta");
            }
            if (!showBreadcumbNavigation.value && !showAdminNavigation.value) {
                classList.push("kl-functional-header--right");
            }

            return classList.join(" ");
        });

        const shoppingCartActive = computed((): boolean => {
            if (hasErrors.value) {
                return false;
            }
            if (!isLoggedOn.value) {
                return false;
            }
            if (route.name === ProcessingTopUp.name || route.name === ProcessingShoppingCart.name) {
                return false;
            }
            return ShoppingCart.isShoppingCartActive;
        });

        const showBreadcumbNavigation = computed((): boolean => {
            if (hasErrors.value) {
                return false;
            }
            if (!isLoggedOn.value) {
                return true;
            }
            return !showAdminNavigation.value
        });

        const navigateToShoppingCart = () => {
            router.push({name: "mrishoppingcart"});
        }


        return {
            hasErrors,
            isUpdating,
            loginLink,
            isLoggedOn,
            isAdmin,
            showAdminNavigation,
            numberItemsInCart,
            functionalHeaderClassName,
            shoppingCartActive,
            showBreadcumbNavigation,
            navigateToShoppingCart,
        }
    }
})
