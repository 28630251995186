import userLocalStorage from '@/storage/user-local-storage';
import {computed, defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
    name: 'KlMaprequestDownload',
    props: {
        downloadUrls: {
            type: Object as () => { [key: string]: string },
            required: false,
            default: () => {}
        },
    },
    setup(props) {

        const downloadPreferenceLocalStorageKey: string = 'download_preference';

        const downloadFiletype = ref<string>('GeoJson');

        const downloadFileUri = computed(() => {
            return props.downloadUrls && downloadFiletype.value ?
                props.downloadUrls[downloadFiletype.value] : null;
        });

        const downloadFiletypeChanged = () => {
            userLocalStorage.setItem(downloadPreferenceLocalStorageKey, downloadFiletype.value);
        }

        onMounted(() => {
            downloadFiletype.value = userLocalStorage.getItem(downloadPreferenceLocalStorageKey) || 'GeoJson';
        });

        return {
            downloadFiletype,
            downloadFileUri,
            downloadFiletypeChanged,
        }
    }
})
