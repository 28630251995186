import {
    defineComponent,
    ref,
    computed,
    watch,
    onMounted,
    inject,
} from "vue";
import {UserData} from "@/app/shared/state/UserDataModule";
import dayjs from "dayjs";
import {
    IUserData,
    IInvoiceListItem,
    IColumn,
    InvoicesAndPaymentReceiptsQuery
} from "@/api/klip-api.proxy";
import {isEqual} from "lodash-es";
import {Mri} from "@/app/mri/shared/services/services";
import * as KlipApi from '@/api/klip-api.proxy';
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    props: {
        organisationId: {
            type: String,
            default: null,
            required: false
        },
        userId: {
            type: String,
            default: null,
            required: false
        },
        isOperationsMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {
        const klipApiProxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const fetching = ref<boolean>(true);
        const invoices = ref<IInvoiceListItem[]>(null);

        const meta = ref<{
            totalRows: number;
            resultsPerPage: number;
        }>({totalRows: 0, resultsPerPage: 50});

        const columns = ref<IColumn[]>([]);

        const downloadFileUriLoading = ref<boolean>(false);
        const downloadFileUri = ref<string>('');
        const unAvailable = ref<boolean>(false);
        const searchFields = ref<Mri.IInvoicesSearchFields>({
            date: {
                from:  dayjs().add(-1, "year").toDate(),
                to:  dayjs().toDate()
            }
        });

        const $refs = ref<{
            searchInvoices: any;
        }>(undefined);

        const displayedInvoices = computed((): IInvoiceListItem[] => {
            return invoices.value || [];
        });

        const user = computed((): IUserData => {
            return UserData.user;
        });

        const showDownloadLink = computed((): boolean => {
            return !!invoices.value && invoices.value.length > 0;
        });

        const search = (value: Mri.IInvoicesSearchFields) => {
            searchFields.value = value;
        };

        const onSearchFieldsChanged = (
            newSearchFields: Mri.IInvoicesSearchFields,
            oldSearchFields: Mri.IInvoicesSearchFields
        ) => {
            if (newSearchFields && oldSearchFields) {
                if (!newSearchFields.date.from && !newSearchFields.date.to) {
                    return;
                }
                if (!isEqual(newSearchFields, oldSearchFields)) {
                    fetching.value = true;
                    fetchInvoices();
                }
            }
        };

        const onDisplayedInvoicesChanged = (invoices: IInvoiceListItem[]) => {
            meta.value.totalRows = invoices != null ? invoices.length : 0;
        };

        const clearSearch = () => {
            $refs.value.searchInvoices.reset();
        };

        const download = () => {
            downloadFileUriLoading.value = true;
            const link = document.createElement("a");
            link.href = downloadFileUri.value;
            link.click();
            URL.revokeObjectURL(link.href);
            downloadFileUriLoading.value = false;
        };

        const cellParser = (row: any, column: IColumn) => {
            switch (column.key) {
                case "submitTimestamp":
                    return {
                        template: `<div>${DateUtil.formatDate(row[column.key])}</div>`,
                    };
                case "totalPrice":
                    return {
                        template: `<div class="vl-u-align-right">€${row[column.key]}</div>`,
                    };
                case "isPaid":
                    return {
                        template: `${
                            row[column.key]
                                ? '<span class="ippro-datatable__cell"><vl-icon icon="check" class="vl-u-align-center" v-vl-text.success mod-small></vl-icon></span>'
                                : "<span></span>"
                        }`,
                    };
                case "invoicePdfUrl":
                    return {
                        template: `${
                            row[column.key]
                                ? `<vl-link href="${
                                    row[column.key]
                                }" mod-icon-only icon="file-office-pdf" hidden-text="Download als PDF" class="vl-u-align-center"></vl-link>`
                                : "<span></span>"
                        }`,
                    };
                case "attachmentCsvUrl":
                    return {
                        template: `${
                            row[column.key]
                                ? `<vl-link href="${
                                    row[column.key]
                                }" mod-icon-only icon="file-office-xls" hidden-text="Download als CSV" class="vl-u-align-center"></vl-link>`
                                : "<span></span>"
                        }`,
                    };
                default:
                    return {template: `<div>${row[column.key]}</div>`};
            }
        };

        const fetchInvoices = () => {
            fetching.value = true;
            const input = new InvoicesAndPaymentReceiptsQuery({
                fromDate: dayjs(searchFields.value.date.from)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
                toDate: dayjs(searchFields.value.date.to)
                    .startOf("day")
                    .format("YYYY-MM-DD"),
                klipUserId: props.userId,
                organisationId: props.organisationId,
            });

            klipApiProxy
                .mri_GetInvoicesAndPaymentReceipts(input)
                .then((response) => {
                    unAvailable.value = response.result.unavailable;
                    downloadFileUri.value = response.result.downloadUrl;
                    invoices.value = response.result.invoices.map((obj) => ({
                        ...obj,
                        attachmentCsvUrl:
                            obj.isTopUpCredit === false ? obj.attachmentCsvUrl : "",
                        visible: true,
                    }));
                })
                .finally(() => {
                    fetching.value = false;
                });
        };

        watch(searchFields, onSearchFieldsChanged, {
            immediate: false,
            deep: true,
        });

        watch(displayedInvoices, onDisplayedInvoicesChanged, {
            immediate: false,
            deep: true,
        });

        onMounted(() => {
            klipApiProxy
                .mri_GetInvoicesAndPaymentReceiptsView()
                .then((response) => {
                    columns.value = response.result.invoiceListColumns.map((column) => ({
                        ...column,
                        parser: cellParser,
                    }));
                    fetchInvoices();
                });
        });

        const showUnavailable = computed((): boolean => {
            return unAvailable.value;
        });

        return {
            fetching,
            invoices,
            meta,
            columns,
            downloadFileUriLoading,
            downloadFileUri,
            searchFields,
            displayedInvoices,
            user,
            showDownloadLink,
            showUnavailable,
            search,
            onSearchFieldsChanged,
            onDisplayedInvoicesChanged,
            clearSearch,
            download,
            cellParser,
            fetchInvoices,
        };
    },
});
