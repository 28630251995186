import {defineComponent, toRefs, ref, computed, watch} from "vue";
import {Mri} from "@/app/mri/shared/services/services";
import dayjs from "dayjs";
import DateUtil from "@/app/shared/helpers/date-util";

export default defineComponent({
    setup(props, context) {
        const dateFrom = ref<Date[]>([dayjs().add(-1, 'year').toDate()]);
        const selectedFromDate = ref<string>(dayjs().add(-1, 'year').toISOString());
        const dateTo = ref<Date[]>([dayjs().toDate()]);
        const selectedToDate = ref<string>(dayjs().toISOString());

        const watchFields = computed(() => {
            return [dateFrom.value, dateTo.value];
        });

        const onFieldChange = () => {
            search();
        };

        const dateFromMaxDate = computed(() => {
            return dateTo.value && dateTo.value.length > 0 ? dayjs(dateTo.value[0]).format('DD/MM/YYYY') : '';
        });

        const dateToMinDate = computed(() => {
            return dateFrom.value && dateFrom.value.length > 0 ? dayjs(dateFrom.value[0]).format('DD/MM/YYYY') : '';
        });

        const search = () => {
            const emitSearch: Mri.IInvoicesSearchFields = {
                date: {
                    from: dateFrom.value && dateFrom.value[0] ? dayjs(dateFrom.value[0]).toDate() : null,
                    to: dateTo.value && dateTo.value[0] ? dayjs(dateTo.value[0]).toDate() : null
                },
            };
            context.emit("search", emitSearch);
        };

        watch(watchFields, onFieldChange, {immediate: false, deep: true});

        return {
            onFieldChange,
            search,
            dateFrom,
            dateFromMaxDate,
            dateTo,
            dateToMinDate,
            selectedFromDate,
            selectedToDate
        };
    },
});
