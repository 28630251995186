import { VNode } from 'vue/types/vnode';
import {computed, defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
    name: 'KlValidationWrapper',
    props: {
        label: { type: String, default: '' },
        rulesLabelOverride: { type: String, default: '' },
        rules: { type: [Object, String], required: false, default: undefined },
        mode: { type: String, default: 'aggressive', required: false },
        annotation: { type: String, required: false, default: undefined },
        modHiddenLabel: { type: Boolean, default: false, required: false },
        modRequired: { type: Boolean, default: false, required: false },
        modNoWrapError: { type: Boolean, default: false, required: false },
        modNoWrapAnnotation: { type: Boolean, default: false, required: false },
    },
    setup(props, { slots }) {

        const acceptedFormFieldComponents = [
            'select',
            'vl-select',
            'vl-input-field',
            'vl-checkbox',
            'vl-checkbox-tile',
            'vl-radio',
            'vl-radio-tile',
            'textarea',
            'vl-textarea',
            'vl-datepicker',
            'kl-datepicker',
            'vl-pill-input',
            'kl-autocomplete',
            'vl-button',
            'multiselect',
        ];

        const id = ref<string>('');
        const names = ref<string[]>([]);


        const rulesLabel = computed((): string => {
            // label = label bij het veld in de ui
            // rulesLabel = naam van het veld in de error text + deze moet uniek zijn binnen de form
            if (props.rulesLabelOverride) {
                return props.rulesLabelOverride;
            }
            return props.label ? props.label : id.value;
        });

        const parsedLabel = computed((): string => {
            return `${props.label}${props.modRequired ? '&nbsp;*' : ''}`;
        });


        /**
         * Traverse search inside an element
         * to find form field component and return
         * their id and names
         */
        const _findFormFieldDetails = (container: VNode[]) => {
            let data: { id: string, names: string[] } = {
                id: '',
                names: [],
            };
            container.forEach((vnode: VNode) => {
                const componentOptions = vnode.componentOptions;
                const tag = componentOptions ? componentOptions.tag : vnode.tag;
                const isSupportedTag = acceptedFormFieldComponents.indexOf(tag) >= 0;
                if (isSupportedTag) {
                    if (tag === 'vl-select') {
                        const propsData = componentOptions.propsData as any;
                        if (!data.id) {
                            data.id = propsData.id;
                        }
                        data.names.push(propsData.name);
                    } else {
                        if (!data.id) {
                            data.id = vnode.data.attrs.id;
                        }
                        data.names.push(vnode.data.attrs.name ? vnode.data.attrs.name : (componentOptions.propsData as any).name);
                    }

                } else if (componentOptions && componentOptions.children) {
                    data = _findFormFieldDetails(componentOptions.children);
                }
            });
            return data;
        }


        onMounted(() => {

            /**
             * Search the default slot for form fields and
             * get the id and names as soon as one is found
             */
            if (slots && slots.default({})) {
                const fields = _findFormFieldDetails(slots.default({}));
                id.value = fields.id;
                names.value = fields.names;
            }
        });


        return {
            id,
            names,
            rulesLabel,
            parsedLabel,
        }
    }
})
