import { defineStore } from 'pinia';
import {Ref, ref} from 'vue';
import {FeatureFlag} from '@/api/klip-api.proxy';
import {klipApiProxy} from '@/plugins/proxy-client';

export enum EFeatureToggle {
    VITE_FEATURE_ENABLE_ZONE_VALIDATION_OPT = 'VITE_FEATURE_ENABLE_ZONE_VALIDATION_OPT',
    VITE_FEATURE_ENABLE_ORG_DRAW_ZONES = 'VITE_FEATURE_ENABLE_ORG_DRAW_ZONES',
    VITE_FEATURE_ENABLE_MRI_DATATABLE_CARDS = 'VITE_FEATURE_ENABLE_MRI_DATATABLE_CARDS',
}

export const useFeatureToggleStore = defineStore('feature-toggle-store', () => {
    const featureFlags: Ref<FeatureFlag[]> = ref([]);
    const fetched: Ref<boolean> = ref(false);

    const fetch = async () => {
        const searchResultResponse = await klipApiProxy.featureFlags_GetAll();
        searchResultResponse?.result?.forEach((flag: FeatureFlag) => featureFlags.value.push(flag));
        fetched.value = true;
    };

    const _addViteEnvFlag = (name: string, description: string) => {
        featureFlags.value.push(FeatureFlag.fromJS({
            name: name,
            description: description,
            valueCurrentEnvironment: import.meta.env[name]?.toLowerCase() === 'true',
            valueTest: '?',
            valueBeta: '?',
            valueProd: '?',
        }));
    };

    const _addViteEnvFlags = () => {
        _addViteEnvFlag(EFeatureToggle.VITE_FEATURE_ENABLE_ZONE_VALIDATION_OPT, 'Enable parallel backend calls for multi zone validation');
        _addViteEnvFlag(EFeatureToggle.VITE_FEATURE_ENABLE_ORG_DRAW_ZONES, 'Enable kl-draw-zone-map component for Klb/Klim zones');
        _addViteEnvFlag(EFeatureToggle.VITE_FEATURE_ENABLE_MRI_DATATABLE_CARDS, 'Enable cards view for the mri maprequests datatable');
    };

    const getFeatureToggle = (name: string): boolean => {
        const featureFlag = featureFlags.value.find((flag: FeatureFlag) => flag.name === name);
        const toggleValue = featureFlag?.valueCurrentEnvironment === true;

        if (import.meta.env.VITE_SHOW_FEATURE_TOGGLES === 'true') {
            console.log(`USING FEATURE TOGGLE: ${name} = ${toggleValue}`);
        }

        return toggleValue;
    }

    _addViteEnvFlags();

    return { fetch, fetched, featureFlags, getFeatureToggle };
});
