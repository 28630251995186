import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { getLength } from 'ol/sphere';
import {IDrawZone} from './components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import {isEmpty} from 'lodash-es';

export default class KlDrawZoneMapUtils {

    public static removeDuplicateCoordinates(feature: Feature, allowMultiPolygon: boolean): boolean {
        // Legacy function: i'm not entirely sure why this is necessary & if this function is called at the best locations.
        //

        // Purpose: perform some geometry cleaning & corrections
        // - remove duplicate points
        // - close polygons
        // - remove holes?


        if (!feature || !feature.getGeometry()) {
            return false;
        }
        if (feature.getGeometry().getType() === 'MultiPolygon') {
            // TODO: refactor into proper Polygon/MultiPolygon support
            // for now: we leave the MultiPolygons alone
            console.assert(allowMultiPolygon, `removeDuplicateCoordinates: unexpected "MultiPolygon"`);
            return true;
        }
        if (feature.getGeometry().getType() !== 'Polygon') {
            console.warn(`Expected geometry type "Polygon", but received "${feature.getGeometry().getType()}"`);
            return false;
        }

        const origCoordinates = feature.getGeometry().getCoordinates();
        const allowedCoordinates = [origCoordinates[0]]; // only use first ring (= no holes?)
        const cleanCoordinates = allowedCoordinates.map((cc: number[][]) => {
            return KlDrawZoneMapUtils._cleanupPolygonCoordinates(cc);
        });

        feature.setGeometry(new Polygon(cleanCoordinates));

        return true;
    }

    private static _cleanupPolygonCoordinates(coordinates: number[][]): number[][] {
        const getKey = (coordinate: number[]): string => coordinate[0] + '' + coordinate[1];

        const dictionary: any = {};
        const filteredPolygonCoordinates = [];

        // filter duplicates
        for (let i = 0; i < coordinates.length; i++) {
            const key = getKey(coordinates[i]);
            if (!dictionary[key]) {
                dictionary[key] = coordinates[i];
                filteredPolygonCoordinates.push(coordinates[i]);
            }
        }

        // close polygon
        filteredPolygonCoordinates.push(coordinates[0]);

        return filteredPolygonCoordinates;
    }

    private static _drawZoneIdCounter: number = 1;

    public static createDrawZone(feature: Feature): IDrawZone {
        const result: IDrawZone = {
            id: KlDrawZoneMapUtils._drawZoneIdCounter++,
            name: 'zone-' + (KlDrawZoneMapUtils._drawZoneIdCounter - 1), // TEMP TEMP TEMP
            coordinates: null,
            area: -1,
            length: -1,
            type: feature?.getGeometry()?.getType(), // 'Polygon', // = type
            crs: {
                type: 'name',
                properties: {
                    name: 'urn:ogc:def:crs:EPSG::31370',
                },
            },
        };
        KlDrawZoneMapUtils.updateDrawZone(result, feature);

        return result;
    }

    public static updateDrawZone(zone: IDrawZone, feature: Feature) {
        if (!feature.getGeometry()) {
            // feature without geometry
            return;
        }

        const geometry = feature.getGeometry();
        const type = geometry.getType();
        const coordinates = geometry.getCoordinates();

        if (type === 'MultiPolygon') {
            // TODO: refactor into proper Polygon/MultiPolygon support?
            zone.coordinates = coordinates;
            zone.area = geometry.getArea();
            zone.length = 0; // te ingewikkeld bij MultiPolygon
            return;
        }
        if (type !== 'Polygon') {
            console.error(`createDrawZone: expected a Polygon, but received ${type}`);
        }

        const cleanCoordinates = [KlDrawZoneMapUtils._cleanupPolygonCoordinates(coordinates[0])];
        const cleanFeature = new Feature({
            geometry: new Polygon(cleanCoordinates)
        });

        zone.coordinates = cleanCoordinates;
        zone.area = KlDrawZoneMapUtils.getArea(cleanFeature);
        zone.length = KlDrawZoneMapUtils.getCircumference(cleanFeature);
    }

    public static getCircumference(polygonFeature: Feature): number {
        if (!polygonFeature || !polygonFeature.getGeometry()) {
            return 0;
        }
        // TODO: double check if this is a polygon
        return Math.ceil(getLength(polygonFeature.getGeometry()));
    }

    public static getArea(polygonFeature: Feature): number {
        if (!polygonFeature || !polygonFeature.getGeometry()) {
            return 0;
        }
        // TODO: double check if this is a polygon
        return KlDrawZoneMapUtils.getPolygonArea(polygonFeature.getGeometry() as Polygon);
    }

    public static getPolygonArea(polygon: Polygon): number {
        if (!polygon) {
            return 0;
        }
        return Math.ceil(polygon.getArea());
    }

    public static formatArea(area: number): string {
        const rounded = Math.ceil(area);
        return `${(rounded >= 1000000 ? (rounded / 1000000).toFixed(rounded > 100000000 ? 0 : 2).toString().replace('.', ',') : rounded).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${rounded >= 1000000 ? 'km²' : 'm²'}`;
    }

    public static updateZoneNames(zones: IDrawZone[], reference: string, manualOverrides: {[id: number]: string}) {
        if (isEmpty(zones)) {
            return;
        }

        const maxLength = 90;

        for (let i = 0; i < zones.length; i++) {
            if (!isEmpty(manualOverrides) && manualOverrides[zones[i].id]) {
                zones[i].name = manualOverrides[zones[i].id];
            }
            else {
                if (isEmpty(reference)) {
                    // no reference
                    zones[i].name = `Zone van ${KlDrawZoneMapUtils.formatArea(zones[i].area)}`;
                }
                else if (zones.length === 1) {
                    // 1 zone
                    zones[i].name = reference;
                }
                else {
                    const safeReference = reference?.substring(0, maxLength);
                    zones[i].name = `${safeReference} (${i+1}/${zones.length})`;
                }
            }
        }
    }
}
