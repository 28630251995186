import { UserData } from '@/app/shared/state/UserDataModule';
import { OrganisationInvoiceData2 } from '@/api/klip-api.proxy';
import { escape } from 'html-escaper';
import {computed, defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'KlInvoiceSettings',
    props: {
        value: {
            type: Object as () => OrganisationInvoiceData2,
            default: () => ({}),
            required: true
        },
        editMode: { type: Boolean, default: true },
        modHideGroup: { type: Boolean, default: false },
        modAdmin: { type: Boolean, default: false },
        error: { type: Boolean, default: false },
    },
    setup(props) {

        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;

        const isSending = ref<boolean>(false);
        const ownReference= ref<string>('');


        const user = computed(() => {
            return UserData.user;
        });

        const title = computed(() => {
            return kboNumber.value ? `${escape(name.value)} - ${kboNumber.value}` : escape(name.value);
        });

        const athumiGateWayUnavailable = computed(() => {
            return props.error || _isAthumiGateWayUnavailable.value;
        });

        const email = computed(() => {
            return props.value && !props.modAdmin ? props.value.email : '';
        });

        const name = computed(() => {
            return props.value && props.value.company ? props.value.company :
                user.value.organisation ? user.value.organisation : '';
        });

        const vatNumber = computed(() => {
            return props.value ? props.value.vatNumber : '';
        });

        const kboNumber = computed(() => {
            return props.value ? props.value.kboNumber : '';
        });

        const _isAthumiGateWayUnavailable = computed(() => {
            return props.value ? props.value.isAthumiGatewayServiceUnavailable : false;
        });

        const contactData = computed(() => {
            const streetParts = `${[props.value.address.street, props.value.address.streetNumber, props.value.address.box].join(' ').trim()}`;
            const cityParts = `${[props.value.address.zip, props.value.address.city].join(' ').trim()}
                ${props.value.address.city && props.value.address.country ?
                ` - ${props.value.address.country}` : ''}`;
            return `${escape(streetParts)}${streetParts && cityParts ? '<br>' : ''}${escape(cityParts)}`.trim();
        });

        const attention = computed(() => {
            return (props.value && props.value.attention) ? props.value.attention : '';
        });

        const displayAttention = computed(() => {
            return vatNumber.value ? `BTW ${escape(vatNumber.value)}` : '';
        });

        const reference = computed(() => {
            return (props.value && props.value.reference) ? props.value.reference : '';
        });

        const isGroup = computed(() => {
            return props.value && props.value.isInGroup && !props.modHideGroup;
        });

        return {
            helpdeskMail,

            isSending,
            ownReference,

            user,
            title,
            athumiGateWayUnavailable,
            email,
            name,
            vatNumber,
            kboNumber,
            contactData,
            attention,
            displayAttention,
            reference,
            isGroup,
        }
    }
})
