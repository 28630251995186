<template>
    <vl-input-group v-if="downloadUrls" class="kl-sidebar__section__actions">
        <vl-select name="download-filetype" id="download-filetype" v-model="downloadFiletype"
                   @input="downloadFiletypeChanged" mod-no-placeholder mod-block>
            <option :value="label" v-for="(url, label) in downloadUrls" :key="label">
                {{ label }}
            </option>
        </vl-select>
        <a :href="downloadFileUri" v-if="downloadFileUri && downloadFiletype" class="vl-button vl-button--narrow" download>Download</a>
    </vl-input-group>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from 'vue';
import userLocalStorage from '@/storage/user-local-storage';

export default defineComponent({
    name: 'KlDrawZoneSidebarDownload',
    props: {
        downloadUrls: { type: Object as () => { [key: string]: string; }, required: false },
    },
    setup(props, { emit }) {

        const _downloadPreferenceLocalStorageKey: string = 'geometry_download_preference';

        const downloadFiletype = ref<string>('GeoJson');

        const downloadFileUri = computed(() => {
            return props.downloadUrls && downloadFiletype.value ? props.downloadUrls[downloadFiletype.value] : null;
        });

        const downloadFiletypeChanged = () => {
            userLocalStorage.setItem(_downloadPreferenceLocalStorageKey, downloadFiletype.value);
        }

        return {
            downloadFiletype,
            downloadFileUri,

            downloadFiletypeChanged,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
