import WKT from 'ol/format/WKT';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {CodeLists} from '@/app/shared/state/CodeListModule';
import {validate, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import validations from '@/plugins/validations';
import KlUploadZone from '@/app/shared/components/kl-upload/kl-upload-zone/kl-upload-zone.vue';
import {first, isEmpty, filter, every, forEach, find, isEqual, cloneDeep, size} from 'lodash-es';
import KlDrawZoneMapUtils from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map-utils';
import {UserData} from "@/app/shared/state/UserDataModule";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import 'vue-custom-scrollbar/dist/vueScrollbar.css';
import GeometryImportService from '@/app/shared/components/kl-draw-zone-map/geometry-import-service';
import {AnalyticsService, EAnalyticsCategory, EAnalyticsDrawZoneActions} from '@/app/shared/service/analytics-service';
import {EFeatureToggle, useFeatureToggleStore} from '@/stores/feature-toggle-store';
import {computed, defineComponent, nextTick, ref, watch} from 'vue';
import {
    IDrawZoneMapGeometry,
    IDrawZoneOrgInfo,
    IDrawZoneGeometryInfo,
} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import KlSidebar from '@/app/shared/components/kl-draw-zone-map/components/kl-sidebar.vue';
import KlDrawZoneSidebarFiller from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';
import KlDrawZoneSidebarHeader from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';
import DateUtil from '@/app/shared/helpers/date-util';
import {useRouter} from '@/plugins/routes';
import {RawLocation} from 'vue-router/types/router';
import KlDrawZoneSidebarDownload
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-download.vue';
import KlDrawZoneSidebarGeometryInfo
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-geometry-info.vue';
import {DrawZoneContext} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-context';
import KlDrawZoneSidebarImport
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-import.vue';
import KlDrawZoneSidebarKlbList
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-klb-list.vue';
import KlDrawZoneSidebarGeometryCreateEdit
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-geometry-create-edit.vue';
import KlDrawZoneSidebarGeometryActions
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-geometry-actions.vue';

extend('required', required);
extend('is_polygon', validations.klipRules.is_polygon);
extend('zone_smaller_than', validations.klipRules.zone_smaller_than);
extend('zone_shorter_than', validations.klipRules.zone_shorter_than);
extend('not_self_intersecting_polygon', validations.klipRules.not_self_intersecting_polygon);
extend('zone_partially_in_flanders', validations.klipRules.zone_partially_in_flanders);
extend('zone_in_buffered_flanders', validations.klipRules.zone_in_buffered_flanders);
extend('zone_geometry_smaller_than_64Kb', validations.klipRules.zone_geometry_smaller_than_64kb);
extend('zone_geometry_smaller_than_x_bytes', validations.klipRules.zone_geometry_smaller_than_x_bytes);

export interface IDrawZone {
    id: number; // used to link DrawZone to ol.Feature
    name?: string;

    isValid?: boolean; // undefined = not validated yet
    errorMessages?: string[];

    price?: number; // = de prijs als dit gebruikt wordt voor een maprequest
    area: number; // = de oppervlakte
    length: number; // = de omtrek
    coordinates: number[][][]; // array of polygon[]; polygon = array of coordinates
    crs: {
        type: string,
        properties: {
            name: string,
        },
    };
    type: string;

    orgZoneInfo?: IDrawZoneOrgInfo;
    geometryInfo?: IDrawZoneGeometryInfo;
}

export enum EDrawZoneMapState {
    overview,
    create,
    edit,
    import
}

export default defineComponent({
    name: 'KlDrawZoneSidebar',
    components: {
        KlDrawZoneSidebarGeometryCreateEdit,
        KlDrawZoneSidebarGeometryInfo,
        KlDrawZoneSidebarGeometryActions,
        KlDrawZoneSidebarKlbList,
        KlDrawZoneSidebarDownload,
        KlUploadZone,
        vueCustomScrollbar,
        KlSidebar,
        KlDrawZoneSidebarFiller,
        KlDrawZoneSidebarHeader,
        KlDrawZoneSidebarImport,
    },
    emits: ['zoom-to-features', 'zoom-to-feature', 'loading', 'state', 'input', 'zone-price', 'remove-geometry'],
    props: {
        modMaprequestZone: {
            type: Boolean,
            default: !!import.meta.env.VITE_DEV_ENABLE_MAPREQUESTZONE_BYDEFAULT,
        },
        modDisabled: {
            type: Boolean,
            default: false,
        },
        reference: {
            type: String,
            default: '',
        },
        rules: {
            type: [Object, String],
            default: '',
        },
        includeImkl: {
            type: Boolean,
            default: !!import.meta.env.VITE_DEV_ENABLE_MAPREQUESTZONE_BYDEFAULT,
        },
        forMaprequest: {
            type: Boolean,
            default: false,
        },
        noConfirmation: {
            type: Boolean,
            default: false,
        },
        modEnableMultiZones: {
            type: Boolean,
            default: !!import.meta.env.VITE_DEV_ENABLE_MAPREQUESTZONE_BYDEFAULT,
        },

        forKlbZones: {
            type: Boolean,
            default: false,
        },
        canAddKlbZone: {
            type: Boolean,
            default: false,
        },
        canAddKlimZone: {
            type: Boolean,
            default: false,
        },
        addKlbZoneLink: {
            type: Object as () => RawLocation,
            default: null,
        },
        addKlimZoneLink: {
            type: Object as () => RawLocation,
            default: null,
        },

        allowMultiPolygons: { type: Boolean, default: false },

        forGeometry: { type: Boolean, default: false },
        showRemoveGeometry: { type: Boolean, default: false },
    },
    setup(props, {emit}) {

        let _context: DrawZoneContext = null;

        const router = useRouter();

        const zones = ref<IDrawZone[]>(null);
        const _zonesBackup = ref<IDrawZone[]>(null);

        const currentZone = ref<IDrawZone>(null);
        const currentZoneError = ref<string>(null); // fix voor vue update trigger (anders ziet vue niet dat de zones[].error is aangepast)

        const importErrors = ref<{ filename: string, errors: string[] }[]>(null);
        const importWarnings = ref<string[]>(null);
        const hasImportErrors = ref(false);
        const hasImportWarnings = ref(false);

        // workflows
        // TO BE REFACTORED: [editZone, createZone, importZone] enkel aanpassen via _setState
        // snelle poging om hier computed props van te maken is gefaald (UI reageert niet correct)
        const _state = ref<EDrawZoneMapState>(EDrawZoneMapState.overview);
        const listZones = ref(false);
        const editZone = ref(false);
        const createZone = ref(false);
        const importZone = ref(false);
        const addingNewZone = ref(false); // = during createZone step 1 & 2 (editZone)

        const editZoneName = ref(false);
        const currentZoneName = ref<string>(null);
        const editZoneNameHasValidationError = ref(false);
        const _manualZoneNames = ref<{ [id: number]: string }>({});
        const _manualZoneNamesBackup = ref<{ [id: number]: string }>({});

        const mapRequestLargePrice = ref(CodeLists.codeLists ? CodeLists.codeLists.mapRequestPrices.mapRequestLarge : null);
        const mapRequestSmallPrice = ref(CodeLists.codeLists ? CodeLists.codeLists.mapRequestPrices.mapRequestSmall : null);
        const imklPrice = ref(CodeLists.codeLists ? CodeLists.codeLists.mapRequestPrices.imklPrice : null);
        const mapRequestSizeTreshold = ref(CodeLists.codeLists ? CodeLists.codeLists.mapRequestPrices.areaThreshold : null);

        // not a computed prop because it is (partially) updated outside the vue scope
        const currentPolygonFeatureArea = ref(0);

        const _processBackendValidationInParallel = ref(useFeatureToggleStore().getFeatureToggle(EFeatureToggle.VITE_FEATURE_ENABLE_ZONE_VALIDATION_OPT));


        const canEditZoneName = computed((): boolean => {
            return props.modEnableMultiZones;
        });

        const importMoreInfoLink = computed((): string => {
            if (!props.forMaprequest) {
                return null;
            }
            return 'https://overheid.vlaanderen.be/help/node/474';
        });

        const maxFilesToImport = computed((): number => {
            return props.modEnableMultiZones ? 10000 : 1;
        });

        const currentPolygonFeatureAreaFormatted = computed((): string | undefined => {
            // empty when 'area = 0'
            if (currentPolygonFeatureArea.value) {
                return KlDrawZoneMapUtils.formatArea(currentPolygonFeatureArea.value);
            }
            return undefined;
        });

        const currentZoneDetailInfo = computed((): string | undefined => {
            // empty when 'area = 0'
            if (currentPolygonFeatureArea.value) {
                return _getZoneDetailInfo(currentPolygonFeatureArea.value);
            }
            return undefined;
        });

        const currentZonePrice = computed((): number | undefined => {
            // empty when 'area = 0'
            if (currentPolygonFeatureArea.value) {
                return _getZonePrice(currentPolygonFeatureArea.value);
            }
            return undefined;
        });

        const zoneDetailTitle = computed(() => {
            return props.modMaprequestZone && currentZone.value?.name ? currentZone.value?.name : `Zone bewerken`;
        });

        const zoneDetailDescription = computed(() => {
            return props.modMaprequestZone ? `Bewerk uw planaanvraagzone op de kaart` : `Bewerk uw zone op de kaart`;
        });

        const applyVat = computed(() => {
            return UserData.applyVat;
        });

        const sidebarTitle = computed(() => {
            if (props.forKlbZones) {
                return 'Zones';
            }

            const suffix = props.modEnableMultiZones ? '(s)' : '';
            return props.modMaprequestZone ? `Uw planaanvraagzone${suffix}` : `Uw zone${suffix}`;
        });

        const sidebarText = computed(() => {
            return `Teken uw ${props.modMaprequestZone ? `planaanvraagzone` : `zone`} op de kaart.<br> Sluit de zone door te dubbelklikken.`;
        });

        const importButtonLabel = computed(() => {
            const suffix = props.modEnableMultiZones ? '(s)' : '';
            return `Importeer zone${suffix}`;
        });

        const canAddMoreZones = computed(() => {
            if (props.forKlbZones) {
                return false;
            }

            return props.modEnableMultiZones || isEmpty(zones.value);
        });

        const hasZones = computed(() => {
            return !isEmpty(zones.value);
        });

        const isConfirmVisible = computed(() => {
            return !props.noConfirmation;
        });

        const isRemoveVisible = computed(() => {
            return (!isConfirmVisible.value || !addingNewZone.value) && editZone.value;
        });

        watch(
            () => props.reference,
            (newRef: string) => {
                KlDrawZoneMapUtils.updateZoneNames(zones.value, newRef, _manualZoneNames.value);
            },
            {immediate: false, deep: true});

        const onCloseWarnings = () => {
            _clearImportErrors();
        }
        const _addImportErrors = (errors: { filename: string, errors: string[] }[]) => {
            if (!importErrors.value) {
                importErrors.value = [];
            }
            // TODO: check if filename is already present
            importErrors.value = importErrors.value.concat(errors);
            hasImportErrors.value = true;
        }
        const _addImportWarning = (warning: string) => {
            if (!importWarnings.value) {
                importWarnings.value = [];
            }
            importWarnings.value.push(warning);
            hasImportWarnings.value = true;
        }
        const _clearImportErrors = () => {
            importErrors.value = null;
            hasImportErrors.value = false;
            importWarnings.value = null;
            hasImportWarnings.value = false;
        }

        const onEditZoneName = () => {
            editZoneName.value = true;
            currentZoneName.value = currentZone.value.name;
            onInputZoneName(currentZoneName.value);
        }
        const onStopEditZoneName = () => {
            if (editZoneNameHasValidationError.value) {
                return;
            }
            if (currentZone.value.name !== currentZoneName.value) {
                currentZone.value.name = currentZoneName.value;
                _manualZoneNames.value[currentZone.value.id] = currentZoneName.value;
                KlDrawZoneMapUtils.updateZoneNames(zones.value, props.reference, _manualZoneNames.value);
            }
            currentZoneName.value = null;
            editZoneName.value = false;
        }
        const onInputZoneName = (newName: string) => {
            editZoneNameHasValidationError.value = size(newName) > 100;
        }

        const _getZonePrice = (area: number): number | undefined => {
            if (props.modMaprequestZone && area) {
                return area > mapRequestSizeTreshold.value ? mapRequestLargePrice.value : mapRequestSmallPrice.value;
            }
            return undefined;
        }

        const getZoneListInfo = (cz: IDrawZone): string => {
            if (props.modMaprequestZone) {
                return cz.area && mapRequestSizeTreshold.value ? `Opp. ${cz.area > mapRequestSizeTreshold.value ? `groter` : `kleiner`} dan ${mapRequestSizeTreshold.value}&nbsp;m²` : null;
            }

            return null;
        }

        const _getZoneDetailInfo = (area: number) => {
            return props.modMaprequestZone && area && mapRequestSizeTreshold.value
                ? `Planaanvraag van ${area > mapRequestSizeTreshold.value ? `>` : `<`}${mapRequestSizeTreshold.value} m²`
                : null;
        }

        // probleem met reactiviteit indien dit een computed is
        const getTotalPrice = (): number => {
            if (!props.modMaprequestZone) {
                return 0;
            }
            let price: number = getTotalImklPrice();
            zones.value?.forEach((cz: IDrawZone) => price += cz.price);
            return price;
        }

        const getTotalImklPrice = (): number => {
            if (props.includeImkl && imklPrice.value && !isEmpty(zones.value)) {
                return imklPrice.value * zones.value.length;
            }
            return 0;
        }

        const getImklDescription = (): string => {
            if (!props.includeImkl || !imklPrice.value || isEmpty(zones.value)) {
                return null;
            }
            if (zones.value.length === 1) {
                return `+ 1 IMKL-pakket (€${imklPrice.value})`;
            }
            return `+ ${zones.value.length} IMKL-pakketten (€${imklPrice.value})`;
        }


        //////////////////////////////////////////////////////////////////////////////
        // TO REFACTOR: for now direct convert of ol events into methods
        const onDrawInteraction_DrawEnd = async (feature: Feature) => {
            await _addDraft(feature);
            _editCurrentZone();
        }
        const onSelectInteraction_Select = async (selected: Feature[], deselected: Feature[]) => {
            if (props.forKlbZones) {
                // in this scenario, the 'select-interaction' is only used to highlight the selected property on the map
                // the actual 'select' action is handle by a map.click event
                return;
            }

            if (!isEmpty(deselected)) {
                const valid = await confirm();

                // re-select when not valid
                if (!valid && currentZone.value) {
                    _selectZone(currentZone.value.id);
                    _editCurrentZone();
                    return;
                }
            }
            if (!isEmpty(selected)) {
                const currentFeature = first(selected);
                // emit('set-current-feature', currentFeature);
                _context.setCurrentFeature(currentFeature);
                edit(currentFeature.drawZoneId);
            }
        }
        const onModifyInteraction_ModifyEnd = async (feature: Feature) => {
            _context.setCurrentFeature(feature);
            //emit('set-current-feature', feature);
            await _updateZone();
        }

        const updateCurrentPolygonFeatureArea = (area: number) => {
            currentPolygonFeatureArea.value = area;
        }
        // END TO REFACTOR
        //////////////////////////////////////////////////////////////////////////////

        const showList = () => {
            _setState(EDrawZoneMapState.overview);
            _setCurrentZone(null);

            //emit('select-feature', null);
            _context.selectFeature(null);
        }

        const create = () => {
            AnalyticsService.SendEvent(EAnalyticsCategory.drawZone, EAnalyticsDrawZoneActions.draw);

            _startAddingNewZones();

            _clearImportErrors();
            _setState(EDrawZoneMapState.create);
            _setCurrentZone(null);

            _context.setCurrentFeature(null);

            // remove remaining messages (old zone, other zones, no zone & required, ..)
            currentPolygonFeatureArea.value = 0;

            _notifyStateAsInvalidBecauseEditing();
        }

        const zoneImport = () => {
            _clearImportErrors();
            _setState(EDrawZoneMapState.import);
        }

        const _addZones = (newZones: IDrawZone[]) => {
            if (!zones.value) {
                zones.value = [];
            }
            zones.value = zones.value.concat(newZones);
            KlDrawZoneMapUtils.updateZoneNames(zones.value, props.reference, _manualZoneNames.value);
        }

        const _allZonesValid = (): boolean => {
            return every(zones.value, (cz: IDrawZone) => !!cz.isValid);
        }

        // warning: side effects > feature is modified
        const _createDrawZone = (feature: Feature): IDrawZone => {
            KlDrawZoneMapUtils.removeDuplicateCoordinates(feature, props.allowMultiPolygons);
            const newZone = KlDrawZoneMapUtils.createDrawZone(feature);
            newZone.price = _getZonePrice(newZone.area); // TODO?? move to KlDrawZoneMapUtils.createDrawZone?

            // TODO: REFACTOR
            // TEMP: add cross-link
            feature.drawZoneId = newZone.id;

            return newZone;
        }

        const _addDraft = async (feature: Feature): Promise<IDrawZone> => {
            _context.addFeature(feature); // add feature to map before awaits (visual optimisation)

            const newZone = _createDrawZone(feature); // modifies feature
            await _validateZoneWithLoading(newZone); // modifies newZone

            _context.setCurrentFeature(feature);

            const area = KlDrawZoneMapUtils.getArea(feature);
            updateCurrentPolygonFeatureArea(area);

            _setCurrentZone(newZone);

            _addZones([newZone]); // modifies this.zones
            _emitResult();
            return newZone;
        }

        const _addDraftOpt = (newFeatures: Feature[], newZones: IDrawZone[]) => {
            forEach(newFeatures, (feature: Feature) => {
                _context.addFeature(feature);
            });

            _addZones(newZones); // modifies this.zones
            _emitResult();
        }

        const confirm = async (): Promise<boolean> => {
            const valid = await _updateZone();
            if (valid) {
                showList();
            }
            return valid;
        }

        const _updateZone = async (): Promise<boolean> => {

            if (editZoneName.value) {
                onStopEditZoneName();
            }

            const currentFeature: Feature = _context.findFeature(currentZone.value.id);
            KlDrawZoneMapUtils.removeDuplicateCoordinates(currentFeature, props.allowMultiPolygons);
            KlDrawZoneMapUtils.updateDrawZone(currentZone.value, currentFeature);
            KlDrawZoneMapUtils.updateZoneNames(zones.value, props.reference, _manualZoneNames.value);
            currentZone.value.price = _getZonePrice(currentZone.value.area); // TODO: move to utils?

            const valid = await _validateZoneWithLoading(currentZone.value);
            currentZoneError.value = first(currentZone.value.errorMessages);

            // when we have inter-zone constraints..
            // const valid = _allZonesValid();

            _emitResult();

            return valid;
        }

        const onCancelImport = () => {
            _clearImportErrors();
            _setState(EDrawZoneMapState.overview);
        }

        const _processFile = async (file: File): Promise<{
            features: Feature[],
            errors: string[],
            zones: IDrawZone[]
        }> => {

            const result: { features: Feature[], errors: string[], zones: IDrawZone[] } = {
                features: [],
                errors: [],
                zones: [],
            };

            if (!GeometryImportService.isFileExtensionSupported(file.name)) {
                result.errors.push('Dit bestandstype is niet toegestaan.');
                return result;
            }

            AnalyticsService.SendEvent(EAnalyticsCategory.drawZone, EAnalyticsDrawZoneActions.import, GeometryImportService.getFileExtension(file.name));

            const wkts = await GeometryImportService.import(file, props.allowMultiPolygons);
            if (isEmpty(wkts)) {
                result.errors.push('Bestand bevat geen geldige zones.');
                return result;
            }

            // create features, zones
            wkts.forEach((wkt: string) => {
                const newFeature = new WKT().readFeature(wkt);
                result.features.push(newFeature);

                const newZone = _createDrawZone(newFeature);
                result.zones.push(newZone);
            });

            // create validation promises
            const processZone = async (zone: IDrawZone) => {
                if (!await _validateZone(zone)) {
                    forEach(zone.errorMessages, (error: string) => {
                        result.errors.push(error);
                    });
                }
            };
            const zoneValidationPromiseFactories = result.zones.map((zone: IDrawZone) => () => new Promise(resolve => resolve(processZone(zone))));

            // parallel or sequential
            if (_processBackendValidationInParallel.value) {
                // TODO: correcte SORT ORDER aanhouden?? (zorgt er enkel voor dat de errors ook in sequentie staan)
                const zoneProcessPromises = zoneValidationPromiseFactories.map(factory => factory());
                await Promise.all(zoneProcessPromises);
            } else {
                for (let i = 0; i < zoneValidationPromiseFactories.length; i++) {
                    await zoneValidationPromiseFactories[i]();
                }
            }

            return result;
        }

        const _findZoneWithSameCoordinates = (zoneToFind: IDrawZone, zones: IDrawZone[]): boolean => {
            return !!find(zones, (zone: IDrawZone) => isEqual(zone.coordinates, zoneToFind.coordinates));
        }

        const _filterDuplicateZones = (features: Feature[], newZones: IDrawZone[]): {
            removed: number,
            features: Feature[],
            zones: IDrawZone[]
        } => {
            if (features.length !== newZones.length) {
                throw new Error('argument exception');
            }
            const result = {
                removed: 0,
                features: [],
                zones: []
            };
            for (let i = 0; i < newZones.length; i++) {
                if (_findZoneWithSameCoordinates(newZones[i], zones.value)) {
                    result.removed++;
                    continue;
                }
                if (_findZoneWithSameCoordinates(newZones[i], result.zones)) {
                    result.removed++;
                    continue;
                }
                result.features.push(features[i]);
                result.zones.push(newZones[i]);
            }
            return result;
        }

        const onFilesUploaded = async (files: File[]) => {
            await _executeWithLoading(async () => await _onFilesUploaded(files));
        }

        const _onFilesUploaded = async (files: File[]) => {
            _clearImportErrors();

            let errors: { filename: string, errors: string[] }[] = [];
            let importedFeatures: Feature[] = [];
            let importedZones: IDrawZone[] = [];
            for (let i = 0; i < files.length; i++) {
                const file: File = files[i];
                const parsed = await _processFile(file);

                // merge results
                if (!isEmpty(parsed.errors)) {
                    errors = errors.concat({filename: file.name, errors: parsed.errors});
                }
                importedFeatures = importedFeatures.concat(parsed.features);
                importedZones = importedZones.concat(parsed.zones);
            }

            if (isEmpty(importedFeatures)) {
                if (!isEmpty(errors)) {
                    _addImportErrors(errors);
                }
                return;
            }

            // check for duplicates
            const filtered = _filterDuplicateZones(importedFeatures, importedZones);

            // the single zone flow
            // navigate to edit mode when:
            // - there was only 1 zone in 1 file (even when it contained errors) = the single zone flow
            // OR !modEnableMultiZones = the single zone flow
            if (!props.modEnableMultiZones || (files.length === 1) && (importedFeatures.length === 1) && (filtered.removed === 0)) {
                const newFeature = first(importedFeatures);
                const newZone = first(importedZones);

                _addDraftOpt([newFeature], [newZone]);
                _editAfterImport(newZone.id);
                return;
            }

            // error flow
            if (!isEmpty(errors)) {
                _addImportErrors(errors);
                return;
            }

            // warnings
            if (filtered.removed === 1) {
                _addImportWarning('Er is 1 zone overgeslagen die reeds bestond.');
            }
            if (filtered.removed > 1) {
                _addImportWarning(`Er zijn ${filtered.removed} zones overgeslagen die reeds bestonden.`);
            }

            // happy flow
            _addDraftOpt(filtered.features, filtered.zones);

            showList();
            emit('zoom-to-features', filtered.features);
        }

        const _executeWithLoading = async <RESULT>(toExecute: () => Promise<RESULT>): Promise<RESULT> => {
            emit('loading', true);

            let result: RESULT;
            try {
                result = await toExecute();
            } catch (ex) {
                console.error(ex);
            }

            emit('loading', false);

            return result;
        }

        const _validateZoneWithLoading = async (cz: IDrawZone): Promise<boolean> => {
            return await _executeWithLoading(async () => await _validateZone(cz));
        }

        const _validateZone = async (cz: IDrawZone): Promise<boolean> => {
            const validationResult = await validate(cz, props.rules);
            cz.errorMessages = validationResult.errors;
            cz.isValid = validationResult.valid;

            return validationResult.valid;
        }

        const remove = async (drawZone: IDrawZone) => {
            const feature = _context.findFeature(drawZone.id);

            _context.removeFeature(feature);

            zones.value = filter(zones.value, (cz: IDrawZone) => cz.id !== drawZone.id);
            KlDrawZoneMapUtils.updateZoneNames(zones.value, props.reference, _manualZoneNames.value);

            if (currentZone.value?.id === drawZone.id) {
                _setCurrentZone(null);
            }

            _emitResult();
        }

        const removeCurrentZone = async () => {
            // can be null when called during original draw of feature
            if (currentZone.value) {
                await remove(currentZone.value);
            }
            showList();
        }

        const revertCurrentZone = async () => {
            // adding new zone?
            if (addingNewZone.value) {
                await removeCurrentZone();
                return
            }

            console.assert(!!_zonesBackup.value, 'revertCurrentZone: _zonesBackup should not be null');
            console.assert(!!_manualZoneNamesBackup.value, 'revertCurrentZone: _manualZoneNamesBackup should not be null');

            zones.value = cloneDeep(_zonesBackup.value);
            _manualZoneNames.value = cloneDeep(_manualZoneNamesBackup.value);

            _emitResult();

            _context.revertFeatureChanges();
            showList();
        }

        const _backupZones = () => {
            _zonesBackup.value = cloneDeep(zones.value);
            _manualZoneNamesBackup.value = cloneDeep(_manualZoneNames.value);
        }

        const _startAddingNewZones = () => {
            _zonesBackup.value = null;
            addingNewZone.value = true;
        }

        const _setCurrentZone = (zone: IDrawZone) => {
            if (!zone) {
                currentZone.value = null;
                currentZoneError.value = null;
                currentZoneName.value = null;
                editZoneName.value = false;
                editZoneNameHasValidationError.value = false;
            } else {
                currentZone.value = zone;
                currentZoneError.value = first(zone.errorMessages);
                currentZoneName.value = currentZone.value.name;
                editZoneName.value = false;
                editZoneNameHasValidationError.value = false;
            }
        }

        const _setState = (state: EDrawZoneMapState) => {
            // console.log('_setState', state);
            _state.value = state;
            if (state === EDrawZoneMapState.overview) {
                listZones.value = true;
                createZone.value = false;
                importZone.value = false;
                editZone.value = false;

                _zonesBackup.value = null;
                addingNewZone.value = false;
            } else if (state === EDrawZoneMapState.create) {
                listZones.value = false;
                createZone.value = true;
                importZone.value = false;
                editZone.value = false;
            } else if (state === EDrawZoneMapState.edit) {
                listZones.value = false;
                createZone.value = false;
                importZone.value = false;
                editZone.value = true;
            } else if (state === EDrawZoneMapState.import) {
                listZones.value = false;
                createZone.value = false;
                importZone.value = true;
                editZone.value = false;
            }

            emit('state', state);
        }

        const _emitResult = () => {

            // TODO: CONVERT IDrawZone to IDrawZoneMapGeometry
            // but, be careful, the emit result is sometimes pushed to the backend

            // for now: enrich zones with wkt, but keep all other info
            const result: IDrawZoneMapGeometry[] = zones.value.map((cz: IDrawZone) => {
                const feature = _context.findFeature(cz.id);
                const geometry = feature?.getGeometry();

                return {
                    ...cz,
                    wkt: geometry ? new WKT().writeGeometry(geometry) : undefined,
                };
            });

            emit('input', result);
            if (props.modMaprequestZone) {
                emit('zone-price', getTotalPrice());
            }
        }

        const _emitEditingResult = () => {
            emit('input', null);
        }

        const _notifyStateAsInvalidBecauseEditing = () => {
            // KAN IK DIT NIET ALTIJD DOEN, IPV enkel bij this.isConfirmVisible??
            // > let op: 'start create' > notify > created > notify > edit > notify [> edit > notify]

            // TODO? ook niet echt logisch.. dit is meer logica voor de parent component
            // reden: hier zouden we beter de state emitten (= 'edit-state'). Het is aan de parent om te beslissen wat hij daarmee doet.
            if (isConfirmVisible.value) {
                _emitEditingResult();
                if (currentZone.value) {
                    currentZone.value.isValid = undefined;
                }
            }
        }

        const _selectZone = (drawZoneId: number) => {
            const selectedFeature: Feature = _context.findFeature(drawZoneId);
            const selectedZone: IDrawZone = zones.value.find((cz: IDrawZone) => cz.id === drawZoneId);
            _setCurrentZone(selectedZone);

            _context.setCurrentFeature(selectedFeature);
            _context.selectFeature(selectedFeature);
        }

        const _editCurrentZone = () => {
            _clearImportErrors();
            _setState(EDrawZoneMapState.edit);
            _notifyStateAsInvalidBecauseEditing();
        }

        const _edit = (drawZoneId: number) => {
            _selectZone(drawZoneId);
            _editCurrentZone();

            const selectedFeature: Feature = _context.findFeature(drawZoneId);
            emit('zoom-to-feature', selectedFeature);
        }

        const _editAfterImport = (drawZoneId: number) => {
            _startAddingNewZones();
            _edit(drawZoneId);
        }

        const edit = (drawZoneId: number) => {
            _backupZones();
            _edit(drawZoneId);
        }

        // TODO: ?? inject routing logic ??
        const editKlbZone = (drawZoneId: number) => {
            const selectedZone: IDrawZone = zones.value.find((cz: IDrawZone) => cz.id === drawZoneId);
            router.push(selectedZone.orgZoneInfo?.editLink);
        }
        const addNewKlbZone = () => {
            router.push(props.addKlbZoneLink);
        }
        const addNewKlimZone = () => {
            router.push(props.addKlimZoneLink);
        }

        const onKlbZoneHover = (drawZoneId: number) => {
            const selectedFeature: Feature = _context.findFeature(drawZoneId);
            _context.selectFeature(selectedFeature);
        }

        const initZones = async (parentContext: DrawZoneContext, zones: IDrawZoneMapGeometry[]) => {

            _context = parentContext;
            _manualZoneNames.value = {};

            if (props.forGeometry) {
                _restoreGeometryZone(first(zones));
                _setState(EDrawZoneMapState.overview);
            }
            else if (props.forKlbZones) {
                _restoreKlbZones(zones);
                _setState(EDrawZoneMapState.overview);
            }
            else {
                if (!isEmpty(zones)) {
                    // TEMP: restore van 1 enkele zone = backward compatible
                    // = momenteel wordt die restore ook enkel gebruikt voor single zones (search, opnieuw indienen maprequest)

                    // LET OP: het wordt ook wel getriggered bij een hot reload!!

                    await _restoreZone(first(zones));
                } else {
                    _setState(EDrawZoneMapState.overview);
                }
            }
        }

        const _restoreGeometryZone = (origZone: IDrawZoneMapGeometry) => {

            if (isEmpty(origZone)) {
                return;
            }

            if (!origZone.wkt) {
                throw new Error('_restoreGeometryZone expects wkt');
            }

            const feature = new WKT().readFeature(origZone.wkt);

            _context.addFeature(feature); // add feature to map before awaits (visual optimisation)
            const newZone = _createDrawZone(feature); // modifies feature

            // GEOMETRY ZONE specific!!
            //newZone.name = currentZone.name;
            newZone.isValid = true;
            newZone.geometryInfo = origZone.geometryInfo;
            feature.geometryInfo = origZone.geometryInfo;

            // using/abusing the semi-automatic naming of zones
            // better: explicitly state these names should not be processed
            _manualZoneNames.value[newZone.id] = origZone.name;

            // _addZones will update the zone.name
            _addZones([newZone]);
            _setCurrentZone(newZone);

            // make sure the ol map is first properly rendered and filled
            nextTick(() => emit('zoom-to-feature', feature));
        }

        const _restoreKlbZones = (origZones: IDrawZoneMapGeometry[]) => {

            if (isEmpty(origZones)) {
                return;
            }

            const features: Feature[] = [];

            const newZones = origZones.map((currentZone: IDrawZoneMapGeometry): IDrawZone => {
                const feature = !!currentZone.wkt
                    ? (new WKT()).readFeature(currentZone.wkt)
                    : isEmpty(currentZone.coordinates)
                        ? new Feature() // = feature without geometry (ex. KLIM-zone)
                        : new Feature({ geometry: new Polygon(currentZone.coordinates) });

                features.push(feature);
                _context.addFeature(feature); // add feature to map before awaits (visual optimisation)
                const newZone = _createDrawZone(feature); // modifies feature

                // ORG ZONE specific!!
                //newZone.name = currentZone.name;
                newZone.isValid = true;
                newZone.orgZoneInfo = currentZone.orgZoneInfo;
                feature.orgZoneInfo = currentZone.orgZoneInfo;

                // using/abusing the semi-automatic naming of zones
                // better: explicitly state these names should not be processed
                _manualZoneNames.value[newZone.id] = currentZone.name;

                return newZone;
            });

            // _addZones will update the zone.name
            _addZones(newZones);

            // make sure the ol map is first properly rendered and filled
            nextTick(() => emit('zoom-to-features', features));
        }

        const _restoreZone = async (zone: IDrawZoneMapGeometry) => {
            const feature = !!zone.wkt
                ? (new WKT()).readFeature(zone.wkt)
                : new Feature({ geometry: new Polygon(zone.coordinates) });

            const draftZone = await _addDraft(feature);
            edit(draftZone.id);
        }

        const onRemoveGeometry = () => {
            emit('remove-geometry');
        }

        return {
            zones,
            currentZone,
            currentZoneError,
            importErrors,
            importWarnings,
            hasImportErrors,
            hasImportWarnings,

            listZones,
            editZone,
            createZone,
            importZone,
            addingNewZone,

            editZoneName,
            currentZoneName,
            editZoneNameHasValidationError,

            mapRequestLargePrice,
            mapRequestSmallPrice,
            imklPrice,
            mapRequestSizeTreshold,

            currentPolygonFeatureArea,

            canEditZoneName,
            importMoreInfoLink,
            maxFilesToImport,
            currentPolygonFeatureAreaFormatted,
            currentZoneDetailInfo,
            currentZonePrice,
            zoneDetailTitle,
            zoneDetailDescription,
            applyVat,
            sidebarTitle,
            sidebarText,
            importButtonLabel,
            canAddMoreZones,
            hasZones,
            isConfirmVisible,
            isRemoveVisible,

            onCloseWarnings,
            onEditZoneName,
            onStopEditZoneName,
            onInputZoneName,
            getZoneListInfo,
            getTotalPrice,
            getTotalImklPrice,
            getImklDescription,

            onDrawInteraction_DrawEnd,
            onSelectInteraction_Select,
            onModifyInteraction_ModifyEnd,
            updateCurrentPolygonFeatureArea,

            showList,
            create,
            zoneImport,
            onFilesUploaded,
            confirm,
            onCancelImport,
            remove,
            removeCurrentZone,
            revertCurrentZone,
            edit,

            editKlbZone,
            addNewKlbZone,
            addNewKlimZone,
            onKlbZoneHover,

            onRemoveGeometry,

            initZones,
        }
    }
})
