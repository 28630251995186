import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useErrorStore = defineStore('error-store', () => {
    const globalError = ref<boolean>(false);
    const lastStatusCode = ref<string>('');
    const bypassErrors = ref<string[]>(null);
    const lastCorrelationId = ref<string>('');

    const setGlobalError = (statusCode: string): void => {
        if (!bypassErrors.value || bypassErrors.value.length === 0 || !bypassErrors.value.includes(statusCode)) {
            globalError.value = true;
            lastStatusCode.value = statusCode;
        }
    }

    const setCorrelationId = (correlationId: string): void => {
        if (!!correlationId) {
            lastCorrelationId.value = correlationId;
        }
    }

    const resetAll = (): void => {
        if (hasErrors.value) {
            resetGlobalError();
            resetBypassError();
        }
    }

    const setBypassError = (statusCodes: string[]): void => {
        bypassErrors.value = statusCodes;
    }

    const resetBypassError = (): void => {
        bypassErrors.value = null;
    }

    const resetGlobalError = (): void => {
        globalError.value = false;
        lastStatusCode.value = null;
    }

    const setStatusCode = (statusCode: string): void => {
        lastStatusCode.value = statusCode;
    }

    const hasErrors = computed((): boolean => {
        return globalError.value;
    })

    return {
        resetAll,
        resetBypassError,
        setBypassError,
        setStatusCode,
        setCorrelationId,
        setGlobalError,
        lastCorrelationId,
        lastStatusCode,
        hasErrors
    }
})