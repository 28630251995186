import { computed, defineComponent, getCurrentInstance, inject, onMounted, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { isEmpty } from "lodash-es";
import { UserData } from "@/app/shared/state/UserDataModule";
import { escape } from 'html-escaper';

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.OrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const root = getCurrentInstance().proxy.$root;
        const klipApiProxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const togglePaysByInvoiceModal = 'toggle-pays-by-invoice';
        const invoiceDataModal = 'invoice-data';
        const athumiIdModal = "athumi-id";
        const viewModelLoading = ref<boolean>(true);
        const isSending = ref<boolean>(false);
        const isSendingDelete = ref<boolean>(false);
        const fetchingAthumiData = ref<boolean>(false);
        const kboInvalid = ref<boolean>(false);
        const switchingToOnlinePayment = ref<boolean>(false);
        const organisationPaysByInvoiceChanged = ref<boolean>(false);
        const organisationDetail = ref<KlipApi.OrganisationDetail>(null);
        const organisationInvoiceData = ref<KlipApi.OrganisationInvoiceData>(null);
        const organisationPaysByInvoice = ref<boolean>(false);
        const athumiId = ref<string>('');

        const organisationChanged = (organisation: KlipApi.OrganisationDetail) => {
            organisationDetail.value = organisation;
        };

        const subOrganisationsCount = computed(() => {
            if (!organisationDetail.value.organisationsDataTable) {
                return 0;
            }

            if (isEmpty(organisationDetail.value.organisationsDataTable) || organisationDetail.value.organisationsDataTable.length === 1) {
                return 0;
            }
            return organisationDetail.value.organisationsDataTable.length;
        });

        const prepaidAmount = computed((): string => {
            const formatter = new Intl.NumberFormat('nl-BE', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(organisationInvoiceData.value.prepaidAmount);
        });

        const isAdmin = computed((): boolean => {
            return UserData.hasRole(['admin']);
        });

        const switchToInvoiceDisabled = computed((): boolean => {
           if(!isAdmin.value) {
               return true;
           }
           return !organisationInvoiceData.value.athumiData.hasValidAthumiId
        });

        const hasValidAthumiId = computed((): boolean => {
           if(organisationInvoiceData.value.athumiData) {
               return organisationInvoiceData.value.athumiData.hasValidAthumiId
           }
           return false;
        });

        const athumiOrganisation = computed((): string => {
            return `${organisationInvoiceData.value.athumiData.organisatieNaam} - ${organisationInvoiceData.value.athumiData.identificatieNummer}`;
        });

        const athumiOrganisationVat = computed((): string => {
            return organisationInvoiceData.value.athumiData.vatNumber;
        });

        const athumiOrganisationContactAddress = computed((): string => {
            const athumiData = organisationInvoiceData.value.athumiData;
            const street = `${athumiData.straat ? escape(athumiData.straat) : ''}${athumiData.straat && athumiData.nummer ? ` ${escape(athumiData.nummer)}` : ''}${athumiData.bus ? ` ${escape(athumiData.bus)}` : ''}<br>`;
            const city = `${athumiData.postcode && athumiData.gemeente ? `${escape(athumiData.postcode)} ` : ''}${athumiData.gemeente ? escape(athumiData.gemeente) : ''}`;
            return `${street}${city}`;
        });

        const saveOrganisationInvoiceData = async () => {
            isSending.value = true;
            const input = new KlipApi.UpdateOperationInvoiceDataInput()
            input.organisationId = props.organisation.organisationId;
            input.reference = organisationInvoiceData.value.reference;
            input.attention = organisationInvoiceData.value.attention;
            input.vatNumber = organisationInvoiceData.value.athumiData.vatNumber;
            input.email = organisationInvoiceData.value.email;
            input.updateChildOrganisations = true;
            input.invoiceName = organisationInvoiceData.value.invoiceName;
            input.invoiceEmail = organisationInvoiceData.value.invoiceEmail;
            input.invoicePhoneNumber = organisationInvoiceData.value.invoicePhoneNumber;

            await klipApiProxy.operations_UpdateOrganisationInvoiceDetail(input);
            isSending.value = false;
            organisationPaysByInvoice.value = true;
            organisationInvoiceData.value.hasInvoiceData = true;
            root.$emit('modal-toggle', invoiceDataModal);
        };

        const saveOrganisationPaysByInvoice = async () => {
            if (organisationPaysByInvoice.value === false) {
                root.$emit('modal-toggle', togglePaysByInvoiceModal);
                isSending.value = true;
            } else {
                await switchToInvoice();
            }
        };

        const toggleCheckbox = () => {
            organisationPaysByInvoiceChanged.value = organisationPaysByInvoice.value !== organisationInvoiceData.value.paysByInvoice;
        };

        const changePaymentType = async () => {
            isSending.value = true;
            const input = new KlipApi.SwitchOrganisationPaymentTypeInput();
            input.organisationId = props.organisation.organisationId;
            input.paysByInvoice = organisationPaysByInvoice.value;
            await klipApiProxy.operations_SwitchOrganisationPaymentType(input);
            isSending.value = false;
        }

        const switchToOnlinePayment = async () => {
            switchingToOnlinePayment.value = true;
            await changePaymentType();
            organisationInvoiceData.value.paysByInvoice = organisationPaysByInvoice.value;
            switchingToOnlinePayment.value = false;
            root.$emit('modal-toggle', togglePaysByInvoiceModal);
        };

        const switchToInvoice = async () => {
            await changePaymentType();
            organisationInvoiceData.value.paysByInvoice = organisationPaysByInvoice.value;
        };

        const cancelSwitchToOnlinePayment = () => {
            switchingToOnlinePayment.value = false;
            organisationPaysByInvoice.value = organisationInvoiceData.value.paysByInvoice;
            isSending.value = false;
        };

        const loadData = async () => {
            const invoiceData: KlipApi.EnvelopeOfOrganisationInvoiceData = await klipApiProxy.operations_GetOrganisationInvoiceDetail(props.organisation.organisationId);
            if (invoiceData.result) {
                organisationInvoiceData.value = invoiceData.result;
                organisationPaysByInvoice.value = invoiceData.result.paysByInvoice;

                if (invoiceData.result.athumiData && invoiceData.result.athumiData.hasValidAthumiId) {
                    fetchingAthumiData.value = false;
                    athumiId.value = invoiceData.result.athumiData.athumiId;
                } else {
                    athumiId.value = null;
                }
            }
            viewModelLoading.value = false;
            root.$on('closed', () => {
                isSending.value = false;
            });
        }


        onMounted(async () => {
            loadData();
        });

        watch(props.organisation, organisationChanged, { immediate: true, deep: true });

        return {
            viewModelLoading,
            isSending,
            isSendingDelete,
            fetchingAthumiData,
            isAdmin,
            prepaidAmount,
            togglePaysByInvoiceModal,
            invoiceDataModal,
            athumiIdModal,
            athumiId,
            athumiOrganisation,
            athumiOrganisationVat,
            athumiOrganisationContactAddress,
            switchingToOnlinePayment,
            organisationDetail,
            organisationInvoiceData,
            organisationPaysByInvoice,
            organisationPaysByInvoiceChanged,
            subOrganisationsCount,
            toggleCheckbox,
            switchToOnlinePayment,
            switchToInvoice,
            switchToInvoiceDisabled,
            hasValidAthumiId,
            cancelSwitchToOnlinePayment,
            saveOrganisationPaysByInvoice,
            saveOrganisationInvoiceData
        };
    }
});
