import { Component, Prop, Vue } from 'vue-property-decorator';
import { klipIndex } from '@/router/router';
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'KlMiniCart',
    props: {
        items: { type: Number, required: true, default: 0 },
    },
    setup(props) {

        const routeKlipIndex = computed(() => {
            return klipIndex;
        });

        const classes = computed(() => {
            return ['kl-mini-cart'];
        });

        const badgeClasses = computed(() => {
            return ['kl-mini-cart__badge'];
        });

        const iconClasses = computed(() => {
            return [
                'kl-mini-cart__icon', {
                    'kl-mini-cart__icon--empty': props.items < 1,
                },
            ];
        });

        return {
            routeKlipIndex,
            classes,
            badgeClasses,
            iconClasses
        }
    }
})
