import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';

dayjs.extend(customParseFormat);

export default defineComponent({
    name: 'KlDatepicker',
    props: {
        placeholder: { type: String, default: 'dd/mm/jjjj' },
        visualFormat: { type: String, default: 'd/m/Y' },
        defaultDate: { type: String, default: '' },
    },
    setup(props, { attrs }) {

        const flatpickr = ref(null);
        const VlDatepicker = ref(null);

        const dates = computed((): string[] => attrs.value as string[]);

        const _onValueChange = (newValue: string[]) => {
            // fix for bugs in WebComponents;
            // bug1 = defaultDate & v-model combination
            // => defaultDate only works when it is a string, but this will cause the v-model to be a string instead of date object when unchanged
            // bug2 = correct parse of input value (locales, timezone, ..)
            if (newValue && newValue.length > 0) {
                flatpickr.value.setDate(newValue, true);
            }
        };

        onMounted(() => {
            flatpickr.value = VlDatepicker.value.$data.flatpickr;

            _onValueChange(dates.value);
        });

        watch(dates, _onValueChange, {immediate: false, deep: true});

        return {
            VlDatepicker,
        }
    }
})
