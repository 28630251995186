import { UserData } from '@/app/shared/state/UserDataModule';
import { ISettingsView } from '../../api/klip-api.proxy';
import {useSettingsStore} from './settings-store';
import {computed, defineComponent, onBeforeMount, onMounted, ref} from 'vue';
import {useMeta} from '@/plugins/meta';

export default defineComponent({
    name: 'SettingsOverview',
    props: {},
    setup(props) {

        const geoSecureLink: string = `${import.meta.env.VITE_GEOSECURE}`;
        const profileCardSubtitle: string = `Bent u dit niet? <a href="/auth/logout">Meld u af</a>.`;

        const error = ref<boolean>(false);
        const lastActiveTab = ref<string>('');

        // @Getter(SettingsStore.store.getters.settingsView, { namespace: SettingsStore.store.namespace })
        const viewModel = computed((): ISettingsView => useSettingsStore().settingsView);

        // @Action(SettingsStore.store.actions.fetchSettings, { namespace: SettingsStore.store.namespace })
        const _fetchSettings = () => useSettingsStore().fetchSettings();

        const acmAuthentication = computed((): boolean => {
            return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
        });

        const viewModelLoaded = computed((): boolean => {
            return !!viewModel.value;
        });

        const loading = computed(() => {
            if (!showTabs.value) {
                return false;
            }
            return !viewModelLoaded.value;
        });

        const profileCardTitle = computed(() => {
            return `${UserData.fullName}${UserData.user.organisation ? ` - ${UserData.user.organisation}` : ''}`;
        });

        const showContactSettings = computed(() => {
            return UserData.hasRole(['mri']);
        });

        const showOrganisationSettings = computed(() => {
            return UserData.hasRole(['mri', 'org_manager', 'una_manager', 'una_operator', 'una_reader', 'klim_admin']) && UserData.user.isCitizen === false;
        });

        const showOrganisationTab = computed((): boolean => {
            if (!showTabs.value && showOrganisationSettings.value) {
                return true;
            }
            return lastActiveTab.value === 'organisation';
        });

        const showTabs = computed(() => {
            return showContactSettings.value && showOrganisationSettings.value;
            // used to be:
            // return [showContactSettings.value, showOrganisationSettings.value].filter((x) => x).length > 1;
        });

        const wrapperComponent = computed(() => {
            return showTabs.value ? 'vl-tabs' : 'div';
        });

        const sectionComponent = computed(() => {
            return showTabs.value ? 'vl-tab' : 'div';
        });


        const tabChange = (tab: { id?: string }) => {
            if (tab && tab.id && lastActiveTab.value !== tab.id) {
                lastActiveTab.value = tab.id;
            }
        }


        onBeforeMount(() => {
            useMeta().setPageTitle("Instellingen");
        });

        onMounted(() => {
            // Je kan naar deze pagina vanuit een email navigeren, en mogelijks is de userdata dan nog niet ingeladen. Vandaar de extra check, om zeker te zijn dat we alles correct tonen
            UserData.updateUserProfile().then(async () => {
                const shouldLoad = showOrganisationSettings.value || showContactSettings.value;
                if (!viewModelLoaded.value && shouldLoad) {
                    await _fetchSettings();
                }
            });
        });

        return {
            geoSecureLink,
            profileCardSubtitle,
            error,
            lastActiveTab,
            viewModel,
            acmAuthentication,
            viewModelLoaded,
            loading,
            profileCardTitle,
            showContactSettings,
            showOrganisationSettings,
            showOrganisationTab,
            showTabs,
            wrapperComponent,
            sectionComponent,
            tabChange
        };
    }
})
