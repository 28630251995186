import { CodeLists } from '@/app/shared/state/CodeListModule';
import { Mri } from '@/app/mri/shared/services/services';
import {isEmpty} from 'lodash-es';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';

export default defineComponent({
    name: 'KlAddressForm',
    emits: ['input'],
    props: {
        value: {
            type: Object as () => Mri.IAddress,
            required: false,
            default: () => ({}),
        }
    },
    setup(props, { emit }) {

        const streetMinLength: number = 3;

        const streetNumber = ref(null);

        const streetData = ref<AutoCompleteResult[]>([]);
        const streetDataFetching = ref<boolean>(false);
        const streetNumberData = ref<AutoCompleteResult[]>([]);
        const streetNumberDataFetching = ref<boolean>(false);

        const address = ref<Mri.IAddress>({
            box: '',
            city: '',
            countryCode: 'BE',
            street: '',
            streetNumber: '',
            zip: '',
        });


        const autocompleteActive = computed(() => {
            return address.value.countryCode === 'BE';
        });

        const countries = computed(() => {
            return CodeLists.countryCodes;
        });


        const onStreetInputChange = () => {
            if (autocompleteActive.value && address.value.street.length > streetMinLength) {
                _fetchStreet(address.value.street);
            }
        }

        const onStreetNumberInputChange = () => {
            if (autocompleteActive.value && address.value.street && address.value.city) {
                _fetchStreetNumbers(address.value.street, address.value.city);
            }
        }

        const onStreetSelect = (street: AutoCompleteResult) => {
            const newAddress = _explodedAddressString(street.value);
            if (!newAddress.street) {
                return;
            }
            address.value.street = newAddress.street;
            if (newAddress.streetNumber) {
                address.value.streetNumber = newAddress.streetNumber;
            } else {
                if (!address.value.streetNumber) {
                    _focusToStreetNumber();
                }
            }
            if (newAddress.box) { address.value.box = newAddress.box; }
            if (newAddress.zip) {
                address.value.zip = newAddress.zip;
            } else {
                if (newAddress.city && newAddress.street) {
                    _fetchZipCodes(newAddress.street, newAddress.city);
                }
            }
            if (newAddress.city) {
                address.value.city = newAddress.city;
            }
        }

        const _focusToStreetNumber = () => {
            streetNumber.value.focus();
        }

        const onStreetNumberSelect = (streetNumber: AutoCompleteResult) => {
            if (streetNumber.value.length) {
                const parts = streetNumber.value.match(/[a-z]+|[^a-z]+/gi);
                if (parts[0]) {
                    address.value.streetNumber = parts[0];
                }
                if (parts[1]) {
                    address.value.box = parts[1];
                }
            }
        }

        const _fetchStreet = (street: string) => {
            streetDataFetching.value = true;
            useKlipApiProxy().location_GetAddressSuggestion(street)
                .then((response) => {
                    streetData.value = response.result
                        .map((item) => {
                            const parts = item.split(',');
                            return {
                                title: parts[0],
                                subtitle: parts[1],
                                value: item,
                            };
                        });
                }).finally(() => {
                streetDataFetching.value = false;
            });
        }

        const _fetchStreetNumbers = (street: string, city: string) => {
            if (street && city) {
                streetNumberDataFetching.value = true;
                useKlipApiProxy().location_GetStreetNumbers(street, city)
                    .then((response) => {
                        if (isEmpty(response.result)) {
                            return;
                        }
                        streetNumberData.value = response.result
                            .filter((streetNumber: string) => {
                                if (isEmpty(streetNumber)) {
                                    return false;
                                }
                                if (isEmpty(address.value?.streetNumber)) {
                                    return true;
                                }
                                return streetNumber.startsWith(address.value.streetNumber);
                            })
                            .map((streetNumber: string) => {
                                return {
                                    title: streetNumber,
                                    value: streetNumber,
                                };
                            });
                    }).finally(() => {
                    streetNumberDataFetching.value = false;
                });
            }
        }

        const _fetchZipCodes = (street: string, city: string) => {
            useKlipApiProxy().location_GetZipCodes(street, city)
                .then((response) => {
                    if (response.result.length !== 0) {
                        address.value.zip = response.result[0];
                    }
                });
        }

        const _explodedAddressString = (address: string) => {
            const explodedAddress: Mri.IAddress = {};
            if (address.length) {
                if (address.includes(', ')) {
                    const addressParts = address.split(', ');
                    const streetDetails = addressParts[0];
                    const cityDetails = addressParts[1];
                    if (streetDetails.includes(' ')) {
                        const lastSpace = streetDetails.lastIndexOf(' ');
                        const firstPart = streetDetails.substr(0, lastSpace);
                        const secondPart = streetDetails.substr(lastSpace + 1);
                        explodedAddress.street = streetDetails;
                        if (parseInt(secondPart, 10)) {
                            explodedAddress.street = firstPart;
                            explodedAddress.streetNumber = secondPart;
                        }
                    }
                    if (!explodedAddress.street) { explodedAddress.street = streetDetails; }

                    if (cityDetails.includes(' ')) {
                        const cityParts = cityDetails.split(' ');
                        // There are some cities with spaces in the name (ex: De Haan, De Pinte), se we need to check if the possible zipcode is a number.
                        const isInvalidZipCode = isNaN(+cityParts[0]);
                        explodedAddress.zip = isInvalidZipCode ? '' : cityParts[0];
                        explodedAddress.city = isInvalidZipCode ? cityDetails : cityParts[1];
                    } else {
                        explodedAddress.city = cityDetails;
                    }
                } else {
                    explodedAddress.street = address;
                }
            }
            return explodedAddress;
        }


        watch(
            () => props.value,
            (val: object) => {
                if (val) {
                    address.value = (val as unknown as Mri.IAddress);
                }
            },
            { immediate: true, deep: true })

        watch(
            address,
            (val: object) => {
                emit('input', val);
            },
            { immediate: false, deep: true })

        watch(
            autocompleteActive,
            (val: boolean, oldVal: boolean) => {
                if (!val) {
                    streetData.value = [];
                    streetNumberData.value = [];
                }
            },
            { immediate: false, deep: false })


        onMounted(() => {
            if (props.value) {
                address.value = props.value;
            }
        });


        return {
            streetNumber,

            streetData,
            streetDataFetching,
            streetNumberData,
            streetNumberDataFetching,
            address,
            autocompleteActive,
            countries,

            onStreetInputChange,
            onStreetNumberInputChange,
            onStreetSelect,
            onStreetNumberSelect,
        }
    }
})
