import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import {Vector as SourceVector} from 'ol/source';
import {ref, Ref} from 'vue';

export class DrawZoneContext {

    private _currentFeature: Ref<Feature> = ref(null);

    // used for 'watch'
    get currentFeatureRef() {
        return this._currentFeature;
    }

    private _featureBackup: Feature = null;

    private _mapFeatures: Collection<Feature> = new Collection();

    public source = new SourceVector({ features: this._mapFeatures });
    public selectedFeatures: Collection<Feature> = new Collection();

    public addFeature(feature: Feature) {
        this._mapFeatures.push(feature);
    }

    public removeFeature(feature: Feature) {
        this._mapFeatures.remove(feature);
        this.selectedFeatures.remove(feature);
    }

    public findFeature(zoneId: number): Feature {
        let feature: Feature = null;
        this._mapFeatures.forEach((f: Feature) => {
            if (f.drawZoneId === zoneId) {
                feature = f;
            }
        });
        return feature;
    }

    public setCurrentFeature(feature: Feature) {
        this._currentFeature.value = feature;
    }
    public getCurrentFeature(): Feature {
        return this._currentFeature.value;
    }

    public selectFeature(feature: Feature) {
        this.selectedFeatures.clear();
        if (feature) {
            this.selectedFeatures.push(feature);
            this._featureBackup = feature.clone();
        }
    }

    public revertFeatureChanges() {
        if ((this.selectedFeatures.getLength() === 0) || !this._featureBackup) {
            return;
        }
        this.selectedFeatures.item(0).setGeometry(this._featureBackup.getGeometry());
    }

    public clear() {
        this._currentFeature.value = null;

        this._featureBackup = null;

        this._mapFeatures.clear();
        this.selectedFeatures.clear();
    }
}
