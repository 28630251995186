import {Address, MapRequestInitiatorContactData} from '@/api/klip-api.proxy';
import {escape} from 'html-escaper';
import {computed, defineComponent} from 'vue';
import {AdminOrganisationDetail, AdminSubOrganisationDetail, AdminUserDetail} from "@/app/admin/admin-routes";
import {RouteConfig} from "vue-router";

export default defineComponent({
    name: 'KlMaprequestContact',
    props: {
        title: { type: String, default: '', required: false },
        contact: { type: Object as () => MapRequestInitiatorContactData, default: null, required: false },
        modSmallTitle: { type: Boolean, default: false, required: false },
        modAdmin: { type: Boolean, default: false, required: false },
    },
    setup(props) {

        const titleTag = computed(() => {
            return props.modSmallTitle ? 'h4' : 'h3';
        });

        const klipOrganisationId = computed(() => {
            return props.contact.klipOrganisationId;
        });

        const klipUserId = computed(() => {
            return props.contact.klipUserId;
        });

        const routeAdminUserDetail = computed((): RouteConfig => {
            return AdminUserDetail;
        });

        const routeAdminSubOrganisationDetail = computed((): RouteConfig => {
            return AdminSubOrganisationDetail;
        });

        const contactCardTitle = computed(() => {
            if (!props.contact) {
                return null;
            }

            const separator = props.contact.firstName && props.contact.lastName ? ' ' : '';

            return `${props.contact.firstName}${separator}${props.contact.lastName}`;
        });

        const contactCardSubtitle = computed(() => {
            if (!props.contact) {
                return '';
            }

            const roleString = props.contact.role || '';
            const companyString = props.contact.company || '';
            const separator = roleString && companyString ? ' - ' : '';

            return`${roleString}${separator}${companyString}` ;
        });

        const addressToContactCard = (address: Address): string => {
            if (!address) {
                return '';
            }

            const additionalLine = address.additionalLine ? `${escape(address.additionalLine)}<br>` : '';
            const street = `${address.street ? escape(address.street) : ''}${address.street && address.streetNumber ? ` ${escape(address.streetNumber)}` : ''}${address.box ? ` ${escape(address.box)}` : ''}<br>`;
            const cityCountry = `${address.zip && address.city ? `${escape(address.zip)} ` : ''}${address.city ? escape(address.city) : ''}${address.city && address.countryCode ? ` - ` : ''}${address.countryCode ? escape(address.countryCode) : ''}`;

            return `${additionalLine}${street}${cityCountry}`;
        }


        return {
            titleTag,
            contactCardTitle,
            contactCardSubtitle,
            addressToContactCard,
            routeAdminUserDetail,
            routeAdminSubOrganisationDetail,
            klipUserId,
            klipOrganisationId
        }
    }
})
