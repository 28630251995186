import { defineComponent, ref, getCurrentInstance } from "vue";
import {ISelectedOrganisation} from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import {EAlertType} from "@/app/shared/state/AlertModule";
import {IAlertHandler} from "@/plugins/alert";

export interface IMapRequestToMove {
    destinationKlipOrganisationId: string;
    destinationParentKlipOrganisationId?: string | undefined;
    reason: string;
}

export class MoveMapRequestNotifications {
    static ShowFailure(alertHandler: IAlertHandler) {
        const toasterId = `move-failed-toaster-${Math.random()
            .toString(36)
            .substring(7)}`;
        const message =
            'Er ging iets mis! Uw planaanvragen zijn NIET in de queue gezet om te verplaatsen. Probeer je even opnieuw?';
        alertHandler.addAlert({
            type: EAlertType.Error,
            id: toasterId,
            title: 'Planaanvragen verplaatsen mislukt',
            message: message,
            icon: 'info-circle',
            autoClose: 5000,
            closable: true,
        });
    }

    static ShowSuccess(alertHandler: IAlertHandler, numberOfMovedMapRequests: number) {
        const toasterId = `move-success-toaster-${Math.random()
            .toString(36)
            .substring(7)}`;
        const message =
            numberOfMovedMapRequests > 1
                ? `Er zijn ${numberOfMovedMapRequests} planaanvragen in de queue gezet om te verplaatsen. U ontvangt een e-mail met het resultaat.`
                : `Er is 1 planaanvraag in de queue gezet om te verplaatsen. U ontvangt een e-mail met het resultaat.`;
        alertHandler.addAlert({
            type: EAlertType.Success,
            id: toasterId,
            title: 'Planaanvragen verplaatsen',
            message: message,
            icon: 'info-circle',
            autoClose: 5000,
            closable: true,
        });
    }
}

export default defineComponent({
    emits: ['move'],
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy.$root;
        const movingMapRequests = ref<boolean>(false);
        const selectedDestinationOrganisation = ref<string>('');
        const reason = ref<string>('');
        const hasDestinationOrganisation = ref<boolean>(false);
        const destinationOrganisation = ref<ISelectedOrganisation>(null);

        const onCloseMoveMapRequestModal = () => {
            destinationOrganisation.value = null;
            hasDestinationOrganisation.value = false;
            reason.value = '';
            selectedDestinationOrganisation.value = '';
            root.$emit('modal-toggle', 'move-map-request-modal');
        };

        const move = () => {
            emit('move', {
                destinationKlipOrganisationId: destinationOrganisation.value.organisationId,
                destinationParentKlipOrganisationId: destinationOrganisation.value.parentId,
                reason: reason.value
            } as IMapRequestToMove)

            onCloseMoveMapRequestModal();
        }

        const onOrganisationSelect = (selectedOrganisation: ISelectedOrganisation) => {
            hasDestinationOrganisation.value = !!selectedOrganisation;
            destinationOrganisation.value = selectedOrganisation;
            selectedDestinationOrganisation.value = selectedOrganisation.name
        };

        return {
            reason,
            movingMapRequests,
            destinationOrganisation,
            hasDestinationOrganisation,
            selectedDestinationOrganisation,
            move,
            onOrganisationSelect,
            onCloseMoveMapRequestModal
        };
    }
});
