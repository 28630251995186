import { useErrorStore } from '../../stores/error-store';
import { defineComponent, onMounted } from 'vue';
import { useRoute } from '@/plugins/routes';

export default defineComponent({
    name: 'Error',
    setup() {
        onMounted(() => {
            const errorCode = useRoute().path.substring(1);
            useErrorStore().setStatusCode(errorCode);
        });
    }
})
