import Vue from 'vue';
import kinks from '@turf/kinks';
import * as KlipApi from '@/api/klip-api.proxy';
import { extend, rules } from '@govflanders/vl-ui-vue-components';
import { IDrawZone } from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import dayjs from 'dayjs';
import { klipApiProxy } from '@/plugins/proxy-client';
import WKT from 'ol/format/WKT';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import DateUtil from '@/app/shared/helpers/date-util';

rules.displayNameIsUnique = {
    params: ['initialName'],
    message: `Er bestaat al een organisatie met deze naam.`,
    validate: async (value: string, { initialName }: Record<string, any>) => {
        if (initialName !== value) {
            const exist = await klipApiProxy.unaSettings_DisplayNameExists(value);
            return !exist.result;
        }
        return true;
    },
};

rules.uniqueNamespace = {
    params: ['id'],
    message: `Er bestaat al een zone met deze namespace.`,
    validate: async (value: string, { id }: Record<string, any>) => {
        const exist = await klipApiProxy.unaSettings_NamespaceExists(value, id);
        return !exist.result;
    },
};

rules.zoneNameIsUnique = {
    params: ['zoneId', 'initialName'],
    message: `Er bestaat al een zone met deze naam.`,
    validate: async (value: string, { zoneId, initialName }: Record<string, any>) => {
        if (initialName !== value) {
            const exist = await klipApiProxy.unaSettings_ZoneNameExist(value, zoneId);
            return !exist.result;
        }
        return true;
    },
};

rules.phone_with_112 = {
    message: `Dit is geen geldig telefoonnummer.`,
    validate: (value: string) => {
        const pattern = /(^|,)(^([\\+]?([0-9 \\(\\)\\/\\-\\.]){9,20})|^112)$/;
        const phoneString = value.toString();

        return pattern.test(phoneString);
    },
};

rules.phone = {
    message: `Dit is geen geldig telefoonnummer.`,
    validate: (value: string) => {
        const pattern = /(^|,)(^([\\+]?([0-9 \\(\\)\\/\\-\\.]){9,20}))$/;
        const phoneString = value.toString();

        return pattern.test(phoneString);
    },
};

rules.max_40_days_ahead = {
    message: `De startdatum mag maximaal 40 werkdagen in de toekomst liggen.`,
    validate: (value: string) => {
        return !((value[0] ? DateUtil.businessDaysBetween(dayjs().startOf('day'), dayjs(value[0])) : 0) > 40);
    },
};

rules.validKlipEmail = {
    message: `Dit is geen geldig email adres.`,
    validate: (value: string) => {
        const pattern = /^[\w.% '!#$&*/=?^_`{|}~+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}$/;
        return pattern.test(value) && value.length <= 320;
    },
};

rules.validKlipEmails = {
    message: `Bevat een ongeldig email adres.`,
    validate: (value: string) => {
        if (!value) return false;

        const pattern = /^[\w.% '!#$&*/=?^_`{|}~+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}$/;

        const emailAddresses = value.split(";").map(m => m.trim()).filter(e => !!e);
        return emailAddresses.every(e => pattern.test(e) && e.length <= 320);
    },
};

rules.tags = {
    message: `Een tag mag geen komma bevatten en moet tussen 3 en 100 karakters lang zijn.`,
    validate: (value: string[]) => {
        return !(value.find((tag) => tag.includes(',')) || value.find((tag) => tag.length < 3 || tag.length > 100));
    },
};

rules.tag = {
    message: `Een tag mag geen komma bevatten en moet tussen 3 en 100 karakters lang zijn.`,
    validate: (value: string) => {
        return !(value.includes(',') || value.length < 3 || value.length > 100);
    },
};

rules.zone_partially_in_flanders = {
    message: `De zone moet minstens een deel in Vlaanderen liggen.`,
    validate: async (value: string) => {
        const input = new KlipApi.IsGeometryIntersectingFlandersInput({
            geometry: JSON.stringify(value),
        });
        const validationResult = await klipApiProxy.validation_IsGeometryIntersectingFlanders(input);
        return validationResult.result;
    },
};

rules.zone_in_buffered_flanders = {
    message: `De zone mag niet meer dan 50m buiten Vlaanderen liggen.`,
    validate: async (value: string) => {
        const input = new KlipApi.IsGeometryWithinBufferedFlandersInput({
            geometry: JSON.stringify(value),
        });
        const validationResult = await klipApiProxy.validation_IsGeometryWithinBufferedFlanders(input);
        return validationResult.result;
    },
};

rules.is_polygon = {
    message: `De zone moet een polygoon zijn. Punten en lijnen zijn niet toegelaten.`,
    validate: (value: IDrawZone) => {
        return !!value.area;
    },
};

rules.not_self_intersecting_polygon = {
    message: `De zone is niet geldig: de zijden mogen elkaar niet snijden.`,
    validate: (value: IDrawZone) => {
        return !kinks(value as any).features.length;
    },
};

rules.zone_smaller_than = {
    params: ['min', 'message'],
    message: `De oppervlakte van de zone moet kleiner zijn dan {message} m².`,
    validate: (value: IDrawZone, { min, message }: Record<string, any> = {}) => {
        return value.area < Number(min);
    },
};

rules.zone_shorter_than = {
    params: ['min', 'message'],
    message: `De omtrek van de zone moet kleiner zijn dan {message} meter.`,
    validate: (value: IDrawZone, { min, message }: Record<string, any> = {}) => {
        return value.length < Number(min);
    },
};

rules.zone_geometry_smaller_than_64kb = {
    message: `De zone bevat te veel coördinaten.`,
    validate: async (value: IDrawZone) => {
        const input = new KlipApi.IsGeometrySmallerThen64KbInput({
            geometry: JSON.stringify(value),
        });
        const validationResult = await klipApiProxy.validation_IsGeometrySmallerThen64Kb(input);
        return validationResult.result;
    },
};

rules.zone_geometry_smaller_than_x_bytes = {
    params: ['max'],
    message: `De zone bevat te veel coördinaten.`,
    validate: (value: IDrawZone, { max, message }: Record<string, any> = {}) => {

        // client-side version of rules.zone_geometry_smaller_than_64kb

        let geometry = null;

        if (value.type === 'Polygon') {
            geometry = new Polygon(value.coordinates);
        }
        if (value.type === 'MultiPolygon') {
            geometry = new MultiPolygon(value.coordinates);
        }

        if (!geometry) {
            console.warn('zone_geometry_smaller_than_x_bytes without valid geometry');
            return false;
        }

        const wkt: string = new WKT().writeGeometry(geometry);
        // const size = new Blob([wkt]).size; // = would be correct size

        // current server-side logic uses c# unicode-16 string
        // > #bytes = wkt.length * 2
        const size = wkt.length * 2;

        return size < max;
    },
};

rules.vatNumberValid = {
    params: ['isCitizen'],
    message: `Ongeldig btw-nummer`,
    validate: (input: string, { isCitizen }: Record<string, any> = {}) => {
        if(isCitizen && (!input || input.length === 0)){
            return true;
        }

        if(!input || input.length < 4){ return false; }

        const country = input.substring(0,2);

        if(!(input.match(/^[a-zA-Z]{2}.*/))){
            return false;
        }
        if(country.toUpperCase() !== 'BE') {
            return true;
        }

        const inputNumber = parseInt(input.substring(2,input.length));
        const inputChecksum = parseInt(input.substring(input.length-2,input.length));

        if(inputChecksum === Number.NaN || inputNumber === Number.NaN){
            return false;
        }

        const result = 97 - (((inputNumber - (inputNumber % 100)) / 100) - (((inputNumber - (inputNumber % 100)) / 100 / 97 - (inputNumber - (inputNumber % 100)) / 100 / 97 % 1) * 97));
        return inputChecksum === (result === 0 ? 97 : result);
    },
}

export default {
    install(vue: typeof Vue): void {
        Object.keys(rules).forEach((rule) => {
            extend(rule, rules[rule]);
        });
    },
    klipRules: rules,
};
