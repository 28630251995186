<template>
    <div class="sidebar-panel">
        <kl-draw-zone-sidebar-header title="Uw zone" tag-style="h5" />

        <vl-sidebar-content class="kl-sidebar__section">
            <vl-action-group class="kl-sidebar__section__actions">
                <vl-button @click.prevent="onImport" mod-small mod-block class="kl-sidebar__section__actions__button">
                    Importeer zone
                </vl-button>
                <vl-button @click.prevent="onCreate" mod-small mod-secondary mod-narrow class="kl-sidebar__section__actions__button">
                    Teken zone
                </vl-button>
            </vl-action-group>
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler />
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from 'vue';
import KlDrawZoneSidebarFiller
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';
import KlDrawZoneSidebarHeader
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';

export default defineComponent({
    name: 'KlDrawZoneSidebarGeometryActions',
    emits: ['goto-import', 'goto-create'],
    components: {
        KlDrawZoneSidebarFiller,
        KlDrawZoneSidebarHeader,
    },
    props: {
    },
    setup(props, { emit }) {

        const onCreate = () => {
            emit('goto-create');
        }
        const onImport = () => {
            emit('goto-import');
        }

        return {
            onCreate,
            onImport,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
