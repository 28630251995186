<template>
<vl-sidebar-header ref="header" class="kl-sidebar__section kl-sidebar__section-smaller">
    <vl-title tag-name="h3" :tag-style="tagStyle" class="vl-sidebar-header__title kl-sidebar__section__fillrow">{{ title }}</vl-title>
    <p v-if="description" class="kl-sidebar__section__fillrow" v-html="description"></p>
</vl-sidebar-header>
</template>

<script lang="ts">

import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        title: { type: String, required: true },
        description: { type: String, required: false },
        tagStyle: { type: String, required: true },
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
