import {computed, defineComponent, ref, watch} from "vue";
import {ServiceHealthStatus, ServiceInfo} from '@/api/klip-api.proxy';
import {uniq} from "lodash";
import {AdminServiceDetail} from "@/app/admin/admin-routes";

export default defineComponent({
    props: {
        service: {
            type: ServiceInfo,
            default: [],
            required: true
        },
        modSimple: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    setup(props) {
        const previousActiveMessagesCounts = ref<number[]>([]);
        const previousDeadLettersCounts = ref<number[]>([]);
        const hasHighLoad = ref<boolean>(false);
        const hasAscendingDeadLetters = ref<boolean>(false);
        const serviceInfo = ref<ServiceInfo>(new ServiceInfo());

        const showLink = computed<boolean>(() => {
            return !props.modSimple;
        });

        const isMessageCountIncreasing = (messageCount: number[]) => {
            const uniqueArray = uniq(messageCount);
            if (uniqueArray.length == 1) {
                return false;
            }
            for (let i = 0; i < uniqueArray.length - 1; i++) {
                if (uniqueArray[i] > uniqueArray[i + 1]) {
                    return false;
                }
            }
            return true; // All pairs are in increasing order
        }

        const routeAdminServiceDetail = computed(() => {
            return AdminServiceDetail
        });

        const messageCountChanged = (info: ServiceInfo) => {
            //Keep track of the last 10 numbers, if this is increasing and the total count is more then 100 the load is high on the queue
            if (previousActiveMessagesCounts.value.length >= 10) {
                previousActiveMessagesCounts.value.shift();
            }

            previousActiveMessagesCounts.value.push(info.activeMessageCount)
            hasHighLoad.value = info.activeMessageCount > 100 && isMessageCountIncreasing(previousActiveMessagesCounts.value);

            if (previousDeadLettersCounts.value.length >= 10) {
                previousDeadLettersCounts.value.shift();
            }

            previousDeadLettersCounts.value.push(info.deadLetterMessageCount)
            hasAscendingDeadLetters.value = info.deadLetterMessageCount >= 10 || isMessageCountIncreasing(previousDeadLettersCounts.value);

            serviceInfo.value = info;
        };

        const queueCssClass = (serviceInfo: ServiceInfo) => {
            if (serviceInfo.healthStatus === ServiceHealthStatus.Unhealthy) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--service-down']
            }

            if (hasAscendingDeadLetters.value) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--dead-letter']
            }

            if (hasHighLoad.value) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--high']
            }

            if (serviceInfo.activeMessageCount > 0) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--normal']
            }

            return ['vl-alert', 'kl-performance-data', 'kl-performance-data--none'];
        }

        watch(() => props.service, messageCountChanged, {immediate: true, deep: true});

        return {
            routeAdminServiceDetail,
            serviceInfo,
            queueCssClass,
            showLink
        };
    }
});
