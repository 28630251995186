<template>
    <div v-if="geometryInfo" class="sidebar-panel">
        <kl-draw-zone-sidebar-header v-if="geometryInfo?.title" :title="geometryInfo?.title" tag-style="h5" />
        <kl-draw-zone-sidebar-header
            class="geometry-info-subheader"
            :title="geometryInfo?.subtitle || ''"
            :description="geometryInfo?.subtitle2"
            tag-style="h6"
        />

        <vl-sidebar-content class="kl-sidebar__section" v-if="showDetails">
            <div class="kl-sidebar__section__edit-zone-detail">
                <template v-if="geometryInfo?.version">
                    <div class="kl-sidebar__section__edit-zone-detail__col-fill" v-if="true">
                        <strong class="kl-sidebar__text13">VERSIE</strong>
                        <p>{{geometryInfo?.version}}</p>
                    </div>
                    <div class="kl-sidebar__section__vertical-separator" v-if="geometryInfo?.lastModificationDate || geometryInfo?.precision"></div>
                </template>
                <template v-if="geometryInfo?.lastModificationDate">
                    <div class="kl-sidebar__section__edit-zone-detail__col-fill" v-if="true">
                        <strong class="kl-sidebar__text13">LAATST BEWERKT</strong>
                        <p>{{geometryInfo?.lastModificationDate}}</p>
                    </div>
                    <div class="kl-sidebar__section__vertical-separator" v-if="geometryInfo?.precision"></div>
                </template>
                <template v-if="geometryInfo?.precision">
                    <div class="kl-sidebar__section__edit-zone-detail__col-fill" v-if="true">
                        <strong class="kl-sidebar__text13">NAUWKEURIGHEID</strong>
                        <p>{{geometryInfo?.precision}}</p>
                    </div>
                </template>
            </div>
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler />

        <vl-sidebar-footer>
            <kl-draw-zone-sidebar-download :download-urls="geometryInfo?.downloadUrls" />
            <vl-button
                v-if="showRemove"
                ref="removeGeometry"
                @click.prevent="onRemoveGeometry"
                mod-secondary
                mod-error
                mod-narrow
                :mod-block="!geometryInfo?.downloadUrls"
                :class="{ 'remove-button-margin': !!geometryInfo?.downloadUrls }"
            >
                Verwijder
            </vl-button>
        </vl-sidebar-footer>
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from 'vue';
import {IDrawZoneGeometryInfo} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import KlDrawZoneSidebarDownload
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-download.vue';
import KlDrawZoneSidebarFiller
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';
import KlDrawZoneSidebarHeader
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';

export default defineComponent({
    name: 'KlDrawZoneSidebarGeometryInfo',
    emits: ['remove-geometry'],
    components: {
        KlDrawZoneSidebarDownload,
        KlDrawZoneSidebarFiller,
        KlDrawZoneSidebarHeader,
    },
    props: {
        geometryInfo: { type: Object as () => IDrawZoneGeometryInfo, required: false },
        showRemove: { type: Boolean, default: false },
    },
    setup(props, { emit }) {

        const showDetails = computed(() => {
            return props.geometryInfo?.version || props.geometryInfo?.lastModificationDate || props.geometryInfo?.precision;
        });

        const onRemoveGeometry = () => {
            emit('remove-geometry');
        }

        return {
            showDetails,
            onRemoveGeometry,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";

.geometry-info-subheader {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.remove-button-margin {
    margin-left: 15px;
}

</style>
