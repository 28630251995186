import {defineComponent} from 'vue';

export default defineComponent({
    name: 'KlLoadingBar',
    props: {
        progress: { type: Number, required: false, default: 0}
    },
    setup(props) {
    }
})
