<template>
    <div class="sidebar-panel">
        <kl-draw-zone-sidebar-header :title="sidebarTitle" tag-style="h5"/>

        <vl-sidebar-content v-if="canAddKlbZone || canAddKlimZone" class="kl-sidebar__section"
                            ref="klb-actions">
            <vl-action-group class="kl-sidebar__section__actions">
                <vl-button v-if="canAddKlbZone" @click="addNewKlbZone" mod-small mod-block
                           class="kl-sidebar__section__actions__button">Zone toevoegen
                </vl-button>
                <vl-button v-if="canAddKlimZone" @click="addNewKlimZone" mod-small mod-secondary mod-block
                           class="kl-sidebar__section__actions__button">KLIM-zone toevoegen
                </vl-button>
            </vl-action-group>
        </vl-sidebar-content>

        <vl-sidebar-content v-if="!hasZones" class="kl-sidebar__section">
            Er zijn geen zones gevonden.
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler v-if="!hasZones"/>

        <vl-sidebar-content mod-container v-if="hasZones">
            <vue-custom-scrollbar
                :settings="{ suppressScrollX: true, suppressScrollY: false, wheelPropagation: false }"
                class="kl-sidebar__scroll-area"
            >
                <ul class="kl-sidebar__list">
                    <li class="kl-sidebar__section" v-for="(cz) in zones" :key="cz.id">
                        <vl-button mod-naked-action @click.prevent="onEditKlbZone(cz.id)"
                                   @mouseover="onKlbZoneHover(cz.id)"
                                   class="kl-sidebar__section__zone-summary kl-sidebar__section__zone-summary__for-klb-zones">
                            <vl-link href="#" class="kl-sidebar__section__zone-summary__title">
                                <img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram"
                                     v-if="cz.orgZoneInfo?.isKlim"/>
                                <strong>{{ cz.name }}</strong>
                            </vl-link>
                            <div class="kl-sidebar__section__zone-summary__sub" v-if="getKlbZoneListInfo(cz)"
                                 v-html="getKlbZoneListInfo(cz)" v-vl-text.small></div>
                        </vl-button>
                    </li>
                </ul>
            </vue-custom-scrollbar>
        </vl-sidebar-content>
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from 'vue';
import KlDrawZoneSidebarFiller
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';
import KlDrawZoneSidebarHeader
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';
import {IDrawZone} from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import {isEmpty} from 'lodash-es';
import DateUtil from '@/app/shared/helpers/date-util';
import vueCustomScrollbar from 'vue-custom-scrollbar';

export default defineComponent({
    name: 'KlDrawZoneSidebarKlbList',
    emits: ['add-new-klb-zone', 'add-new-klim-zone', 'edit-klb-zone', 'highlight-klb-zone'],
    components: {
        KlDrawZoneSidebarFiller,
        KlDrawZoneSidebarHeader,
        vueCustomScrollbar,
    },
    props: {
        zones: {
            type: Array as () => IDrawZone[],
            default: () => [],
        },
        sidebarTitle: { type: String, default: null },
        canAddKlbZone: { type: Boolean, default: false },
        canAddKlimZone: {type: Boolean, default: false },
    },
    setup(props, { emit }) {

        const hasZones = computed(() => {
            return !isEmpty(props.zones);
        });

        const getKlbZoneListInfo = (cz: IDrawZone): string => {
            let orgZoneInfo = `${cz.orgZoneInfo?.guid}<br/>${cz.orgZoneInfo?.namespace}`;
            if (cz.orgZoneInfo?.willBeActivatedOn && !cz.orgZoneInfo.isKlim) {
                orgZoneInfo += `<br/>Wordt geactiveerd op ${DateUtil.formatDate(cz.orgZoneInfo?.willBeActivatedOn)}`;
            }

            return orgZoneInfo;
        }

        const onEditKlbZone = (zoneId: number) => {
            emit('edit-klb-zone', zoneId);
        }

        const onKlbZoneHover = (zoneId: number) => {
            emit('highlight-klb-zone', zoneId);
        }

        const addNewKlbZone = (zoneId: number) => {
            emit('add-new-klb-zone', zoneId);
        }

        const addNewKlimZone = (zoneId: number) => {
            emit('add-new-klim-zone', zoneId);
        }

        return {
            hasZones,
            getKlbZoneListInfo,
            onEditKlbZone,
            onKlbZoneHover,
            addNewKlbZone,
            addNewKlimZone,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
