import FunctionalHeader from './shared/components/kl-functional-header/kl-functional-header.vue';
import { AlertModule, IAlertNotification } from './shared/state/AlertModule';
import { UserData } from './shared/state/UserDataModule';
import { CodeLists } from './shared/state/CodeListModule';
import { useErrorStore } from '../stores/error-store';
import userLocalStorage from '@/storage/user-local-storage';
import { IApplicationInfo } from '@/api/klip-api.proxy';
import { settings } from './settings/settings-routes';
import * as widgetClient from '@govflanders/vl-widget-client'
import { Widget } from '@govflanders/vl-widget-core/types/plugin'
import "reflect-metadata"
import '@govflanders/vl-widget-polyfill'
import axios from 'axios';
import { klipApiProxy } from '@/plugins/proxy-client';
import {AlertHandler, useAlertHandler} from '@/plugins/alert';
import { MetaHandler } from '@/plugins/meta';
import {signalrHub, useSignalrHub} from "@/plugins/signalr";
import {computed, defineComponent, onMounted, watch, onUnmounted} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';


export default defineComponent({
    name: 'App',
    components: {
        FunctionalHeader,
    },
    provide: {
        axiosClient: axios,
        klipApiProxy: klipApiProxy,
        signalrHub: signalrHub,
        alerts: AlertHandler,
        meta : MetaHandler
    },
    props: {},
    setup(props) {

        const sessionTimeoutLocalStorageKey: string = 'sessiontimeout_redirect';

        const acmHeaderUrl: string = import.meta.env.VITE_ACM_HEADER_URL;
        const acmFooterUrl: string = import.meta.env.VITE_ACM_FOOTER_URL;

        const route = useRoute();
        const router = useRouter();

        const applicationInfo = computed((): IApplicationInfo => {
            return CodeLists && CodeLists.applicationInfo;
        });

        const isCheckingAuthentication = computed((): boolean => {
            return UserData && UserData.isCheckingAuthentication;
        });

        const userLoggedOn = computed((): boolean => {
            return UserData && UserData.loggedOn;
        });

        const alerts = computed((): IAlertNotification[] => {
            const pageSpecificAlerts = AlertModule.pageAlerts(route.name);
            const globalAlerts = AlertModule.globalAlerts;

            return [...pageSpecificAlerts, ...globalAlerts];
        });

        const hasErrors = computed((): boolean => {
            return useErrorStore().hasErrors || (!!UserData.user && (UserData.user.roleError || !!UserData.user.validationError));
        });

        const acmAuthentication = computed((): boolean => {
            return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
        });

        const absoluteUrl = computed((): string => {
            return import.meta.env.VITE_BASE_URI;
        });

        const routeSettings = computed(() => {
            return settings;
        });


        // @Getter('authenticated', { namespace: UserDataModule.namespace })
        const authenticated = computed((): boolean => UserData.authenticated);


        const close = (id: string) => {
            useAlertHandler().removeAlert(id);
        }



        const _generateHeader = () => {
            if (acmAuthentication.value) {
                widgetClient.bootstrap(acmHeaderUrl).then((widget: Widget) => {
                    widget.setMountElement(document.getElementsByClassName('global-header')[0]);
                    widget.mount();
                    widget.getExtension('citizen_profile.session').then((session: any) => {
                        session.configure({
                            endpoints: {
                                loginUrl: `${absoluteUrl.value}/auth/login`,
                                switchCapacityUrl: `${absoluteUrl.value}/auth/switch`,
                                loginRedirectUrl: `${absoluteUrl.value}/`,
                                logoutUrl: `${absoluteUrl.value}/auth/logout`
                            }
                        });
                    });
                })

            } else {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', `https://widgets.vlaanderen.be/widget/live/0839942423b142f997d405c86a16170f`);
                xhr.send();
                xhr.onload = () => {
                    const script = (xhr.response as string).replace(
                        /document\.write\((.*?)\);/,
                        'document.getElementsByClassName("global-header")[0].innerHTML = $1;',
                    );
                    eval(script);
                };
            }
        }

        const _generateFooter = async () => {
            if (acmAuthentication.value) {
                const footer = await widgetClient.bootstrap(acmFooterUrl)
                footer.setMountElement(document.getElementsByClassName('global-footer')[0])
                footer.mount()
            } else {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', `https://widgets.vlaanderen.be/widget/live/f826ff458f734342afc3590c226893cd`);
                xhr.send();
                xhr.onload = () => {
                    const script = (xhr.response as string).replace(
                        /document\.write\((.*?)\);/,
                        'document.getElementsByClassName("global-footer")[0].innerHTML = $1;',
                    );
                    eval(script);
                };
            }
        }

        const _widgetCapture = () => {
            widgetClient.capture((widget: Widget) => {
                const userActive = UserData.isAuthenticated;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                widget.getExtension('citizen_profile.session').then((session: any) => {
                    session.configure({ active: userActive });
                });
            });
        }

        const _widgetActivity = () => {
            widgetClient.capture((widget: Widget) => {
                if (widget.getPluginTypeId() === 'global_header') {
                    // Get the Citizen Profile Session extension from the global header widget.
                    widget.getExtension('citizen_profile.session').then((session: any) => {
                        /**
                         * Event handler which extends a Citizen Profile session.
                         */
                        function activityEventHandler() {
                            // Inform the Citizen Profile Session extension about activity.
                            session.extend()
                        }

                        // Build a list of event names which should be used for activity tracking.
                        const eventNames = [
                            'mousedown',
                            'mousemove',
                            'mousewheel',
                            'DOMMouseScroll',
                            'scroll',
                            'wheel',
                            'keydown',
                            'keypress',
                            'touchmove',
                            'touchstart'
                        ]
                        // Iterate through the events names.
                        for (const eventName of eventNames) {
                            // Register our event handler given event name.
                            window.addEventListener(eventName, activityEventHandler)
                        }
                    })
                }
            });
        }


        const _onAlertsChanged = (alerts: IAlertNotification[], oldAlerts: IAlertNotification[]) => {
            const autoCloseAlerts = alerts.filter((alert) => alert.autoClose);
            const autoCloseAlertsOld = oldAlerts.filter((alert) => alert.autoClose);
            if (JSON.stringify(autoCloseAlerts) !== JSON.stringify(autoCloseAlertsOld)) {
                if (autoCloseAlerts) {
                    autoCloseAlerts.forEach((alert) => {
                        setTimeout(() => {
                            useAlertHandler().removeAlert(alert.id);
                        }, alert.autoClose);
                    });
                }
            }
        }

        const _onAuthenticatedChanged = () => {
            if (!authenticated.value) {
                return;
            }
            _widgetCapture();
            _widgetActivity()
        }

        const  _onApplicationInfoChanged = (newApplicationInfo: IApplicationInfo) => {
            if (!newApplicationInfo) {
                return;
            }

            // check if the user's session timed out, redirect to last location
            const routePath = userLocalStorage.getItem(sessionTimeoutLocalStorageKey);
            if (routePath) {
                userLocalStorage.removeItem(sessionTimeoutLocalStorageKey);
                router.push({ path: routePath });
            }

            const buildNumberElement = document.querySelector('head meta[name="klip_buildnumber"]');
            const versionElement = document.querySelector('head meta[name="klip_version"]');
            const environmentElement = document.querySelector('head meta[name="klip_environment"]');

            buildNumberElement.setAttribute('content', newApplicationInfo.buildNumber);
            versionElement.setAttribute('content', newApplicationInfo.version);
            environmentElement.setAttribute('content', newApplicationInfo.environment);

            if (newApplicationInfo.environment === 'Beta') {
                const favicon = document.querySelector('link[rel="icon"]');
                favicon.setAttribute('href', import.meta.env.BASE_URL + 'favicon.beta.ico');
                document.title = 'KLIP BETA - Kabel- en Leidinginformatieportaal | Digitaal Vlaanderen';
            }

            // console.log(`KLIP ${newApplicationInfo.version}`);
        }

        watch(
            alerts,
            _onAlertsChanged,
            { immediate: false, deep: true });

        watch(
            authenticated,
            _onAuthenticatedChanged,
            { immediate: true, deep: true });

        watch(
            applicationInfo,
            _onApplicationInfoChanged,
            { immediate: false, deep: true });


        onMounted(() => {
            _generateHeader();
            _generateFooter();
        });

        onUnmounted(() => {
            useSignalrHub().stop();
        });


        return {
            acmHeaderUrl,
            acmFooterUrl,

            applicationInfo,
            isCheckingAuthentication,
            userLoggedOn,
            alerts,
            hasErrors,
            acmAuthentication,
            absoluteUrl,
            routeSettings,

            close,
        }
    }
})
