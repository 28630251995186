import { defineComponent, ref} from "vue";
import {useRoute} from '@/plugins/routes';

  export default defineComponent({
    props: {},
    setup(_props) {
      const route = useRoute();
      const userId = ref<string>(route.params.userId);
      const organisationId = ref<string>(route.params.organisationId);
      return {
        userId,
        organisationId
      };
    },
  });
