<template>
    <div class="sidebar-panel">
        <kl-draw-zone-sidebar-header :title="title" tag-style="h5"/>
        <vl-sidebar-content class="kl-sidebar__section kl-sidebar__section-smaller">
            <vl-title tag-name="h3" tag-style="h6" class="vl-sidebar-header__title kl-sidebar__section__fillrow">
                {{importTitle}}
            </vl-title>
            <vl-button mod-small mod-tertiary @click="onCancelImport">Annuleren</vl-button>
        </vl-sidebar-content>
        <vl-sidebar-content class="kl-sidebar__section-upload">
            <kl-upload-clientside
                @files-upload="onFilesUploaded"
                :mod-hide-guide-lines="hasImportErrors || hasImportWarnings"
                :max-files="maxFilesToImport"
                :more-info-link="importMoreInfoLink"
                :guidelines="uploadGuidelines"
            />
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler v-if="!hasImportErrors"/>

        <vl-sidebar-content mod-container v-if="hasImportErrors">
            <vue-custom-scrollbar
                :settings="{ suppressScrollX: true, suppressScrollY: false, wheelPropagation: false }"
                class="kl-sidebar__scroll-area"
            >
                <div class="kl-sidebar__error">
                    <vl-title tag-style="h6" class="kl-sidebar__text18 kl-sidebar__margin-bottom5">Er is iets mis met uw
                        bestand(en)
                    </vl-title>
                    <div class="kl-sidebar__margin-top5" v-for="(fileErrors, index) in importErrors" :key="fileErrors.filename + '-' + index">
                        <div class="kl-sidebar__text18 kl-sidebar__margin-bottom5">{{ fileErrors.filename }}</div>
                        <div class="vl-typography">
                            <ul>
                                <li v-for="(error, index2) in fileErrors.errors" :key="fileErrors.filename + '-' + index + '-' + index2">{{ error }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </vue-custom-scrollbar>
        </vl-sidebar-content>
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from 'vue';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import KlDrawZoneSidebarHeader
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';
import KlDrawZoneSidebarFiller
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';
import KlUploadClientside from '@/app/shared/components/kl-upload/kl-upload-clientside/kl-upload-clientside.vue';
import {IGuideline} from '@/app/shared/components/kl-upload/guideline';

export default defineComponent({
    name: 'KlDrawZoneSidebarImport',
    emits: ['cancel', 'files-uploaded'],
    components: {
        KlUploadClientside,
        KlDrawZoneSidebarHeader,
        KlDrawZoneSidebarFiller,
        vueCustomScrollbar,
    },
    props: {
        title: { type: String, required: true },

        forMaprequest: {type: Boolean, default: false }, // TODO: refactor? Maybe easier to provide guidelines?
        forGeometry: {type: Boolean, default: false }, // TODO: refactor? Maybe easier to provide guidelines?

        modEnableMultiZones: { type: Boolean, default: false },
        hasImportErrors: { type: Boolean, required: true },
        hasImportWarnings: { type: Boolean, required: true },
        maxFilesToImport: { type: Number, required: true },
        importMoreInfoLink: { type: String, default: null },
        importErrors: { type: Array as () => { filename: string, errors: string[] }[], default: null },
    },
    setup(props, { emit }) {

        const uploadGuidelines = computed(() => {

            const guidelines: IGuideline[] = [];
            guidelines.push({
                label: 'Aanvaarde bestandsformaten',
                value: 'GeoJSON, Shapefile of WKT',
            });
            if (props.forMaprequest) {
                guidelines.push({
                    label: 'Maximale oppervlakte',
                    value: '100.000m²',
                });
            }
            guidelines.push({
                label: 'Maximale bestandsgrootte',
                value: props.forGeometry ? '100MB (als WKT, UTF-16 gecodeerd)' : '64kB (als WKT, UTF-16 gecodeerd)',
            });
            guidelines.push({
                label: 'Projectie',
                value: 'Lambert 72',
            });
            if (props.maxFilesToImport > 1) {
                if (!props.forGeometry) {
                    guidelines.push({
                        value: 'Bevat geen multipolygonen',
                    });
                }
            }
            else {
                guidelines.push({
                    value: props.forGeometry ? 'Bevat maar 1 (multi)polygoon' : 'Bevat maar 1 polygoon',
                });
            }

            return guidelines;
        });

        const onCancelImport = () => {
            emit('cancel');
        }

        const onFilesUploaded = async (files: File[]) => {
            emit('files-uploaded', files);
        }

        const importTitle = computed(() => {
           if (props.modEnableMultiZones) {
               return 'Importeer zone(s)';
           }
           return 'Importeer zone';
        });

        return {
            importTitle,
            uploadGuidelines,
            onCancelImport,
            onFilesUploaded,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
