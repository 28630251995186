import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Collection from 'ol/Collection';
import { Vector as LayerVector } from 'ol/layer';
import { Vector as SourceVector } from 'ol/source';
import {computed, defineComponent, nextTick, onMounted, ref} from 'vue';
import {IpproMap} from '@/plugins/ipproComponents';

export default defineComponent({
    name: 'KlMaprequestMap',
    props: {
        zoneGeometry: {
            type: Object as () => string | { coordinates: number[][][] },
            required: false,
            default: undefined
        },
        downloadUrls: {
            type: Object as () => { [key: string]: string },
            required: false,
            default: () => {}
        },
    },
    setup(props) {

        const map= ref<InstanceType<typeof IpproMap>>(null);
        const mapInstance = ref(null);
        const sidebar = ref(null);

        const maprequestZoneFeature = computed((): Feature => {
            const maprequestPolygon = new Polygon(geometry.value.coordinates);
            const maprequestFeature = new Feature(maprequestPolygon);
            maprequestFeature.setStyle(new Style({
                stroke: new Stroke({
                    color: 'rgba(21, 155, 170, 0.8)',
                    width: 1,
                }),
            }));
            return maprequestFeature;
        });

        const donutFeature = computed(() => {
            const bounds = [20000, 246000, 259000, 150000];
            const donutPolygon = new Polygon([[
                [bounds[0], bounds[1]],
                [bounds[0], bounds[3]],
                [bounds[2], bounds[3]],
                [bounds[2], bounds[1]],
                [bounds[0], bounds[1]],
            ]]);
            const mapRequestRing = (maprequestZoneFeature.value.getGeometry() as Polygon).getLinearRing(0);
            donutPolygon.appendLinearRing(mapRequestRing);
            const donut = new Feature(donutPolygon);
            donut.setStyle(new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.5)',
                }),
            }));
            return donut;
        });

        const geometry = computed((): { coordinates: number[][][] } => {
            if (typeof props.zoneGeometry === 'string') {
                return JSON.parse(props.zoneGeometry);
            }
            return props.zoneGeometry;
        });


        const zoomToMapRequestZone = () => {
            // nextTick to allow the map to init & render
            nextTick(() => {
                map.value.zoomToExtent(maprequestZoneFeature.value.getGeometry().getExtent(), 1);
            });
        }

        const _drawZoneGeometry = () => {
            if (mapInstance.value && geometry.value && maprequestZoneFeature.value && donutFeature.value) {
                const maprequestZoneLayer = new LayerVector({
                    source: new SourceVector({ features: new Collection() }),
                });

                maprequestZoneLayer.getSource().addFeature(maprequestZoneFeature.value);
                maprequestZoneLayer.getSource().addFeature(donutFeature.value);

                mapInstance.value.addLayer(
                    maprequestZoneLayer,
                );

                zoomToMapRequestZone();
            }
        }

        const toggleSidebar = () => {
            sidebar.value.toggle();
        }


         onMounted(() => {
            mapInstance.value = map.value.instance;
            _drawZoneGeometry();
        });


        return {
            map,
            sidebar,

            maprequestZoneFeature,
            donutFeature,
            geometry,
            zoomToMapRequestZone,

            toggleSidebar,
        }
    }
})
