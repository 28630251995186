import _Vue from 'vue';
import { VlTooltip, VlModalToggle } from '@govflanders/vl-ui-vue-components';
import {camelCase, upperFirst} from 'lodash-es';
import {focus} from 'vue-focus';

export default function directives(Vue: typeof _Vue, options?: any): void {

    const requireDirective = import.meta.glob(
        '@/app/shared/directives/**/*.ts',
        { eager: true }
    );

    Object.entries(requireDirective).forEach(([path, directiveConfig]) => {
        const directiveName = upperFirst(
            camelCase(path.split('/').pop().replace(/\.\w+$/, ''))
        );

        Vue.directive(
            directiveName,
            (directiveConfig as any).default || directiveConfig
        );
    })


    Vue.directive('vl-tooltip', VlTooltip);
    Vue.directive('vl-modal-toggle', VlModalToggle);

    Vue.directive('focus', focus);
}
