import { Mri } from '@/app/mri/shared/services/services';

export class InvoiceDetails {
    address: Mri.IAddress;
    attention?: string;
    editInvoiceDetailsMode: boolean;
    emailAddress: string;
    invoiceDetailsPrefilledByCookie: boolean;
    organisation: string;
    rememberContactDetails: boolean;
    useAlternativeEmail: boolean;
    vatNumber: string;
    ownReference: string;
    isTopupPrepaid: boolean;
    failedLoadingActor: boolean;
}
