import {computed, defineComponent, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { useRoute } from "@/plugins/routes";
import { RouteConfig } from "vue-router";
import { IUnaZone } from "./una-zone-model";
import { AdminSubOrganisationDetailUnaZone, AdminSubOrganisationDetailUnaKlimZone } from "@/app/admin/admin-routes";
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import {EFeatureToggle, useFeatureToggleStore} from '@/stores/feature-toggle-store';

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.SubOrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');

        const klbZonesKey = ref<string>('klbZonesKey');

        const displayName = ref<String>(props.organisation.displayName);
        const confirmation = ref<boolean>(props.organisation.unaData.confirmation);
        const confirmationExampleUri = ref<string>(props.organisation.unaData.confirmationExampleUri);
        const subjectSigningCertificate = ref<string>(props.organisation.subjectSigningCertificate);
        const route = useRoute();
        const zonesFetching = ref<boolean>(true);
        const isSending = ref<boolean>(false);
        const onlySearchActiveZones = ref<boolean>(true);
        const zones = ref<IUnaZone[]>([]);
        const routeUnaKlimZone = ref<RouteConfig>(AdminSubOrganisationDetailUnaKlimZone);
        const routeUnaZone = ref<RouteConfig>(AdminSubOrganisationDetailUnaZone);

        const useDrawZoneComponent = ref(useFeatureToggleStore().getFeatureToggle(EFeatureToggle.VITE_FEATURE_ENABLE_ORG_DRAW_ZONES));

        const drawZones = computed(() => {
            const today = new Date();

            const myNamespaces = zones.value;
            return myNamespaces.map((zone, index): IDrawZoneMapGeometry => {

                const activationDate = new Date(zone.zoneGeometryActivationDate);
                const isActive = zone.isKlimZone || !zone.zoneGeometryActivationDate || (activationDate < today);

                return {
                    name: zone.zoneName,
                    wkt: zone.zoneGeometry,
                    orgZoneInfo: {
                        isKlim: zone.isKlimZone,
                        guid: zone.zoneId,
                        namespace: zone.namespace,
                        isActive: isActive,
                        willBeActivatedOn: !isActive ? activationDate : undefined,
                        editLink: {
                            name: zone.link?.route?.name,
                            params: { zoneId: zone.zoneId },
                        },
                    }
                }
            });
        });

        const search = async (value: boolean) => {
            onlySearchActiveZones.value = value;
            await _loadZones();

            // force an update of the kl-draw-zone-map component
            klbZonesKey.value = klbZonesKey.value + '*';
        };

        const parseCell = (row: IRow, column: IColumn) => {
            if (column.key === zonesColumns.value[0].key) {
                return {
                    template: `
                    <kl-router-link :to='${JSON.stringify(row.link.route)}' :params='${JSON.stringify(row.link.params)}'>
                        <div class='zone-name'>
                            ${row.isKlimZone ? `<img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram" />` : ''}
                            <span>${row.zoneName}${row.isArchived ? ` (gearchiveerd)` : ''}</span>
                        </div>
                        <span class="vl-u-text--light">${escape(row.zoneId)}</span>
                    </kl-router-link>`,
                };
            } else {
                return {
                    template: `<kl-router-link :to='${JSON.stringify(row.link.route)}' :params='${JSON.stringify(row.link.params)}'> ${escape(row[column.key])}</kl-router-link>`,
                };
            }
        }

        const zonesColumns = ref<IColumn[]>([{
            key: 'zoneName',
            label: 'Zone',
            sortable: false,
            width: 55,
            isVisible: true,
            parser: parseCell,
        }, {
            key: 'namespace',
            label: 'Namespace',
            sortable: false,
            isVisible: true,
            parser: parseCell,
        }]);

        const _loadZones = async () => {
            const unaZones = (await proxy.organisation_LoadUnaZonesForOrganisation(props.organisation.organisationId, !onlySearchActiveZones.value)).result;
            zones.value = unaZones
                .filter((item) => item.zoneId !== route.params.removedItem)
                .sort((a, b) => {
                    // first order on isArchived
                    if (a.isArchived !== b.isArchived) {
                        return a.isArchived ? 1 : -1;
                    }
                    // then order on isKlimZone
                    if (a.isKlimZone !== b.isKlimZone) {
                        return a.isKlimZone ? -1 : 1;
                    }

                    return 0;
                })
                .map((zone) => ({
                    zoneId: zone.zoneId,
                    zoneName: zone.zoneName,
                    namespace: zone.namespace,
                    isKlimZone: zone.isKlimZone,
                    unaId: zone.unaId,
                    zoneGeometryActivationDate: zone.zoneGeometryActivationDate,
                    zoneGeometry: zone.zoneGeometry,
                    link: {
                        route: zone.isKlimZone ? routeUnaKlimZone : routeUnaZone,
                        params: {
                            organisationId: props.organisation.organisationId,
                            zoneId: zone.zoneId,
                        },
                    },
                }));

            zonesFetching.value = false;
        }

        const updateConfirmationInUnaZoneSettings = async () => {
            isSending.value = true;

            const input = new KlipApi.UpdateConfirmationInUnaZoneSettingsInput();
            input.unaId = props.organisation.unaData.unaId;
            input.confirmation = confirmation.value;

            await proxy.operations_UpdateConfirmationInUnaZoneSettings(input)
            isSending.value = false;
        }

        onMounted(async () => {
            await _loadZones();
        });

        return {
            klbZonesKey,

            displayName,
            confirmation,
            isSending,
            confirmationExampleUri,
            subjectSigningCertificate,
            zonesColumns,
            zones,
            zonesFetching,
            updateConfirmationInUnaZoneSettings,
            search,

            drawZones,
            useDrawZoneComponent,
        };
    }
});

