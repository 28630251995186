import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import router from '@/router/router';
import { signalrHub } from '@/plugins/signalr';
import {
    EnvelopeOfBoolean,
    IUserData,
    IEnvelopeOfUserData,
    PaymentTypes
} from '@/api/klip-api.proxy';
import { CodeLists } from './CodeListModule';
import userLocalStorage from '@/storage/user-local-storage';
import { ShoppingCart } from './ShoppingCartModule';
import { klipApiProxy } from '@/plugins/proxy-client';
import {useFeatureToggleStore} from '@/stores/feature-toggle-store';


@Module({
    dynamic: true,
    store,
    name: UserDataModule.namespace,
    namespaced: true,
})
export class UserDataModule extends VuexModule {
    public static readonly namespace = 'UserDataModule';

    public user: IUserData = {};
    public isUpdating: boolean = false;
    public isAuthenticated: boolean = false;
    public isCheckingAuthentication: boolean = true;
    public scope: string = '';

    public get fullName(): string {
        let fullName = '';
        if (this.user.firstName) {
            fullName += this.user.firstName;
        }

        if (this.user.lastName) {
            if (this.user.firstName) {
                fullName += ' ';
            }
            fullName += this.user.lastName;
        }
        return fullName;
    }

    public get loggedOn(): boolean {
        if (!this.user) {
            return false;
        }
        return !!this.user.klipUserId;
    }

    public get hasRole() {
        return (roles: string[]): boolean => {
            if (roles.length > 0) {
                if (this.user && this.user.roles) {
                    return this.user.roles.some((role) => roles.includes(role)).valueOf();
                }
                return false;
            } else {
                return true;
            }
        };
    }

    get applyVat(): boolean {
        return !!this.user && this.user.applyVat == true;
    }

    get vatPercentage(): number {
        return !!this.user && !!this.user.vatPercentage ? this.user.vatPercentage : 0;
    }

    public get paymentType(): PaymentTypes {
        if (!this.user) {
            return PaymentTypes.None;
        }
        return this.user.paymentType;
    }


    get authenticated(): boolean {
        return this.isAuthenticated;
    }


    public get scopes() {
        const scopes = [];
        if (this.hasRole(['una_manager', 'una_operator', 'una_reader', 'klim_admin'])) {
            scopes.push({
                key: 'una',
                abbr: 'KLB',
                label: 'Kabel- en leidingbeheerder',
            });
        }
        if (this.hasRole(['pda_manager'])) {
            scopes.push({
                key: 'pda',
                abbr: 'ODB',
                label: 'Openbaar Domeinbeheerder',
            });
        }
        if (this.hasRole(['mri', 'mrr'])) {
            scopes.push({
                key: 'mri',
                abbr: 'PAV',
                label: 'Planaanvrager',
            });
        }
        if (this.hasRole(['helpdesk', 'admin'])) {
            scopes.push({
                key: 'admin',
                abbr: 'CP',
                label: 'Contactpunt',
            });
        }
        return scopes;
    }

    public get userId() {
        return this.user.klipUserId;
    }

    public get organisationId() {
        return this.user.klipOrganisationApiId;
    }

    get acmAuthentication(): boolean {
        return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
    }

    public get isContactpuntUser(): boolean {
        return this.user.isContactPunt || this.hasRole(['helpdesk']) || this.hasRole(['admin']);
    }

    @Action({ rawError: true })
    public async updateUserProfile(hardRefresh?: boolean) {
        this.updatingProfile(true);
        const authUserDataCall = this.acmAuthentication ? klipApiProxy.acmIdmAuth_GetUserData(hardRefresh) : klipApiProxy.geoSecureAuth_GetUserData(hardRefresh);
        return authUserDataCall
            .then(async (userDataEnvelope: IEnvelopeOfUserData) => {
                const userData = userDataEnvelope.result;
                userLocalStorage.setItem('klip_visited', true);
                this.mutationUpdateUserProfile(userData);
                if (signalrHub) {
                    if (signalrHub.connected) {
                        signalrHub.subscribeToContactNotifications(userData.klipUserId);
                        if (!userData.isCitizen) signalrHub.subscribeToOrganisationNotifications(userData.klipOrganisationApiId);
                    } else {
                        signalrHub.eventEmitter.on('connected', () => {
                            signalrHub.subscribeToContactNotifications(userData.klipUserId);
                            if (!userData.isCitizen) signalrHub.subscribeToOrganisationNotifications(userData.klipOrganisationApiId);
                        });
                    }
                }
                await useFeatureToggleStore().fetch();
                await CodeLists.updateCodeList();
                this.getScope(userData);
                await ShoppingCart.updateShoppingCart();
            }).finally(() => {
                this.updatingProfile(false);
            });
    }

    @Action({ rawError: true })
    public async isUserAuthenticated(): Promise<EnvelopeOfBoolean> {
        this.updateIsCheckingAuthentication(true);
        const auth_IsAuthenticatedCall = this.acmAuthentication ? klipApiProxy.acmIdmAuth_IsAuthenticated() : klipApiProxy.geoSecureAuth_IsAuthenticated();

        return auth_IsAuthenticatedCall.then((isAuthenticated) => {
            if (isAuthenticated.result) {
                signalrHub.start();
            }
            this.updateIsAuthenticated(isAuthenticated.result);
            return Promise.resolve(isAuthenticated);
        });
    }

    @Action({ rawError: true })
    public setShoppingCartInvisible() {
        this.mutationSetShoppingCartInvisible();
    }

    @Mutation
    public mutationUpdateUserProfile(user: IUserData) {
        this.user = user;
    }

    @Mutation
    public getScope(user: IUserData) {
        if (localStorage && user && user.klipUserId) {
            const scopeFromLocalStorage = userLocalStorage.getItem('scope');
            //check if the user still has the correct roles when loading the scope from the localstorage.
            switch (scopeFromLocalStorage) {
                case 'mri':
                    if (user.roles.some((role) => ['mri', 'mrr'].includes(role)).valueOf()) {
                        this.scope = scopeFromLocalStorage;
                    }
                    break;
                case 'una':
                    if (user.roles.some((role) => ['una_manager', 'una_operator', 'una_reader', 'klim_admin'].includes(role)).valueOf()) {
                        this.scope = scopeFromLocalStorage;
                    }
                    break;
                case 'pda':
                    if (user.roles.some((role) => ['pda_manager'].includes(role)).valueOf()) {
                        this.scope = scopeFromLocalStorage;
                    }
                    break;
                case 'admin':
                    if (user.roles.some((role) => ['helpdesk', 'admin'].includes(role)).valueOf()) {
                        this.scope = scopeFromLocalStorage;
                    }
                    break;
            }
        }
        if (!this.scope) {
            this.scope =
                user.roles.some((role) => ['mri', 'mrr'].includes(role)) ? 'mri' :
                    user.roles.some((role) => ['una_manager', 'una_operator', 'una_reader', 'klim_admin'].includes(role)) ? 'una' :
                        user.roles.some((role) => ['pda_manager'].includes(role)) ? 'pda' :
                            user.roles.some((role) => ['helpdesk', 'admin'].includes(role)) ? 'admin' : '';
        }
    }

    @Mutation
    public setScope(scope: string) {
        if (localStorage && this.user && this.user.klipUserId) {
            userLocalStorage.setItem('scope', scope);
        }
        this.scope = scope;
    }

    @Mutation
    public updatingProfile(isUpdating: boolean) {
        this.isUpdating = isUpdating;
    }

    @Mutation
    public updateIsCheckingAuthentication(isChecking: boolean) {
        this.isCheckingAuthentication = isChecking;
    }

    @Mutation
    public updateIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
    }

    @Mutation
    public cleanProfile(redirect = true) {
        this.user = {};
        if (redirect) {
            router.push('/');
        }
    }

    @Mutation
    public mutationSetShoppingCartInvisible() {
        this.user.isShoppingCartVisible = false;
    }

    @Mutation
    public mutationUpdateDisplayName(displayName: string) {
        this.user.displayName = displayName;
    }
}

export const UserData = getModule(UserDataModule);
