import {computed, defineComponent, inject, onMounted, ref, watch,} from 'vue';
import {useRoute, useRouter} from '@/plugins/routes';
import * as KlipApi from '@/api/klip-api.proxy';
import {
    CodeListValueOrder,
    MoveMapRequestsInput,
    SearchOperationsMapRequestForOrganisationType
} from '@/api/klip-api.proxy';
import KlSearchMaprequests, {
    MapRequestSearchField
} from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import {AdminArchivedMapRequestDetail, AdminMapRequestDetail} from '@/app/admin/admin-routes';
import dayjs from 'dayjs';
import QueryFilter from "@/app/shared/helpers/queryFilter";
import {UserData} from '@/app/shared/state/UserDataModule';
import {isEmpty} from "lodash-es";
import {
    IMapRequestToMove,
    MoveMapRequestNotifications
} from '@/app/admin/components/kl-move-map-requests/kl-move-map-requests';
import { IpproDatatable } from "@/app/shared/components/ippro-datatable/ippro-datatable-declare";
import {IAlertHandler} from '@/plugins/alert';
import OrganisationHelper from "@/app/shared/helpers/organisation-helper";

export interface IAdminOrganisationRequestedMapRequestListItem {
    id?: string;
    reference?: string | undefined;
    requester?: string | undefined;
    organisation?: string | undefined;
    dateReceived?: string;
    status?: string | undefined;
    isMoving?: boolean;
    type?: string | undefined;
}

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.OrganisationDetail,
            default: null,
            required: true
        },
        mapRequestType: {
            type: String,
            default: 'Requested',
            required: true,
        }
    },
    setup(props) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const alertHandler = inject<IAlertHandler>('alerts');

        const router = useRouter();
        const route = useRoute();

        const queryFilter = new QueryFilter();
        let initialMappingFromQuery = true;
        const viewModelLoading = ref<boolean>(false);
        const searchMaprequests = ref<InstanceType<typeof KlSearchMaprequests>>(null);
        const ismapRequestTypeRequested = ref<boolean>(props.mapRequestType === 'Requested');
        const moveMapRequestChecked = ref<boolean>(false);
        const moveMapRequests = ref<boolean>(false);
        const mapRequestsColumns = ref<KlipApi.IColumn[]>([]);
        const mapRequestsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });
        const mapRequestsFetching = ref<boolean>(true);
        const mapRequestsSearchFields = ref<KlipApi.ICodeListValueOrder[]>();
        const mapRequestsSearchOptions = ref<KlipApi.ICodeListValueOrder[]>();
        const mapRequestDefaultSearchOption = ref('allstatuses');
        const mapRequests = ref<IAdminOrganisationRequestedMapRequestListItem[]>(null);
        const checkedMapRequests = ref<IAdminOrganisationRequestedMapRequestListItem[]>([]);
        const mapRequestsInput = ref<KlipApi.IGetMapRequestsInput2>();
        const selectedOrganisation = ref<string>('all');
        const selectedZone = ref<string>('all');
        const archivedSelected = ref<boolean>(false);
        const organisations = ref<CodeListValueOrder[]>([]);
        const zones = ref<CodeListValueOrder[]>([]);
        const defaultFilter = ref<MapRequestSearchField>(new MapRequestSearchField());
        let oldMapRequestInput: string;

        const cellParser = (row: IpproDatatable.IRow, column: IpproDatatable.IColumn) => {
            const routeParams = {
                id: row.id,
            };
            switch (column.key) {
                case 'dateReceived':
                    return {
                        template: `<div>${dayjs(row[column.key]).format('DD/MM/YYYY - HHumm')}</div>`,
                    };
                case 'id':
                    const id = row[column.key];
                    const routerLink = archivedSelected.value ?
                        `<kl-router-link :to='${JSON.stringify(AdminArchivedMapRequestDetail)}' :params='${JSON.stringify(routeParams)}'>${id}</kl-router-link>` :
                        `<kl-router-link :to='${JSON.stringify(AdminMapRequestDetail)}' :params='${JSON.stringify(routeParams)}'>${id}</kl-router-link>`;
                    return {
                        template: routerLink
                    };
                case 'organisation':
                    const cellValue = row[column.key] === null ? 'Particulier' : row[column.key];
                    return {
                        template: `<div>${cellValue}</div>`,
                    };
                default:
                    return {
                        template: `<div>${row[column.key]}</div>`
                    };
            }
        };

        const isAdmin = computed((): boolean => {
            return UserData.hasRole(['admin']);
        })

        const search = (searchFields: MapRequestSearchField) => {
            if (!initialMappingFromQuery) {
                mapRequestsFetching.value = true;
                const orderBy = mapRequestsInput.value.orderBy;
                const orderDirection = mapRequestsInput.value.orderDirection;
                mapRequestsInput.value = {
                    parentKlipOrganisationId: selectedOrganisation.value === 'all' ? props.organisation.organisationId : null,
                    klipOrganisationId: selectedOrganisation.value === 'all' ?  null : selectedOrganisation.value,
                    utilityNetworkAuthorityZoneId: selectedZone.value === 'all' ?  null : selectedZone.value,
                    offset: 0,
                    limit: mapRequestsMeta.value.resultsPerPage,
                    orderBy: orderBy,
                    orderDirection: orderDirection,
                    mapRequestForOrganisationType: ismapRequestTypeRequested.value ? SearchOperationsMapRequestForOrganisationType.Requested : SearchOperationsMapRequestForOrganisationType.Received,
                    query: searchFields.keyword,
                    searchField: KlipApi.SearchField[searchFields.searchField as keyof typeof KlipApi.SearchField],
                    mapRequestStatus: searchFields.searchOption !== 'allstatuses' ? searchFields.searchOption : '',
                    isArchived: searchFields.archive,
                    startDate: searchFields.date.from && searchFields.date.from[0] ? searchFields.date.from[0] : null,
                    endDate: searchFields.date.to && searchFields.date.to[0] ? searchFields.date.to[0] : null,
                    zoneGeometry: searchFields.location ? JSON.stringify(searchFields.location) : null,
                };
                archivedSelected.value = searchFields.archive;
                mapRequestsMeta.value.currentPage = 1;
                queryFilter.mapQuery(route, mapRequestsInput.value);
            }
            initialMappingFromQuery = false;
        };

        const onMoveMapRequests = (moveMapRequest: IMapRequestToMove) => {
            const request = new MoveMapRequestsInput();
            request.mapRequestIds = checkedMapRequests.value.filter(c => !c.isMoving).map(c => c.id);
            request.destinationKlipOrganisationId = moveMapRequest.destinationKlipOrganisationId;
            request.reason = moveMapRequest.reason;
            request.isArchived = archivedSelected.value;
            request.query = new KlipApi.GetMapRequestsInput2(mapRequestsInput.value);

            proxy.operations_MoveMapRequests(request).then(() => {
                //if the mapRequestIds is empty, the users has selected move all.
                if (isEmpty(request.mapRequestIds)) {
                    mapRequests.value.forEach((m) => {
                        m.isMoving = true;
                        m.type = 'processing'
                    });
                    MoveMapRequestNotifications.ShowSuccess(alertHandler, mapRequestsMeta.value.totalRows);
                } else {
                    request.mapRequestIds.forEach((id) => {
                        const mapRequest = mapRequests.value.find(m => m.id == id);
                        mapRequest.isMoving = true;
                        mapRequest.type = 'processing';
                    });
                    MoveMapRequestNotifications.ShowSuccess(alertHandler, request.mapRequestIds.length);
                }
            }).catch(() => {
                MoveMapRequestNotifications.ShowFailure(alertHandler);
            });
        };

        const checkboxChanged = (checkedRows: IAdminOrganisationRequestedMapRequestListItem[]) => {
            checkedMapRequests.value = checkedRows;
        };

        const hasCheckedMapRequest = computed(() => {
            return isEmpty(checkedMapRequests.value);
        })

        const moveMapRequestsChanged = (checked: boolean) => {
            moveMapRequestChecked.value = checked;
        }

        const fetchMapRequestsCount = async () => {
            const input = new KlipApi.GetMapRequestsInput2(mapRequestsInput.value);
            const response = await proxy.operations_GetMapRequestsCount(input);
            mapRequestsMeta.value.totalRows = response.result;
        };

        const fetchMapRequests = async () => {
            const input = new KlipApi.GetMapRequestsInput2(mapRequestsInput.value);
            const response = await proxy.operations_GetMapRequests(input);
            mapRequests.value = response.result.map((x) => {
                return {
                    ...x, type: x.isMoving ? 'processing' : ''
                };
            });
        };

        const onMapRequestsInputChanged = async (mapRequestInput: KlipApi.IGetMapRequestsInput2) => {
            if (!mapRequestInput) {
                return;
            }

            const mapRequestInputJson = JSON.stringify(mapRequestInput);
            if (mapRequestInputJson === oldMapRequestInput) {
                return;
            }

            oldMapRequestInput = mapRequestInputJson;
            mapRequestsFetching.value = true;
            await fetchMapRequestsCount();
            await fetchMapRequests();
            mapRequestsFetching.value = false;
        };

        const onOrganisationChanged = (organisationId: string) => {
            if(!organisationId || !mapRequestsInput.value){
                return;
            }
            if(organisationId === 'all') {
                mapRequestsInput.value.parentKlipOrganisationId = props.organisation.organisationId;
                mapRequestsInput.value.klipOrganisationId = null;

            } else {
                mapRequestsInput.value.parentKlipOrganisationId = null;
                mapRequestsInput.value.klipOrganisationId = organisationId;
            }
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.limit = mapRequestsMeta.value.resultsPerPage;
            mapRequestsMeta.value.currentPage = 1;
            queryFilter.mapQuery(route, mapRequestsInput.value);
        }

        const onZoneChanged = (zoneId: string) => {
            if(!zoneId || !mapRequestsInput.value){
                return;
            }
            mapRequestsInput.value.utilityNetworkAuthorityZoneId = zoneId === 'all' ? null : zoneId;
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.limit = mapRequestsMeta.value.resultsPerPage;
            mapRequestsMeta.value.currentPage = 1;
            queryFilter.mapQuery(route, mapRequestsInput.value);
        };

        const clearSearch = () => {
            selectedOrganisation.value = 'all';
            selectedZone.value = 'all';
            searchMaprequests.value.reset();
            queryFilter.removeQuery(route);
        };

        const pagerClicked = (page: number) => {
            mapRequestsInput.value.offset = mapRequestsMeta.value.resultsPerPage * (page - 1);
            queryFilter.mapQuery(route, mapRequestsInput.value);
        }

        const columnClicked = (column: KlipApi.IColumn) => {
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.orderBy = column.key.toLowerCase();
            mapRequestsInput.value.orderDirection = column.direction;
            queryFilter.mapQuery(route, mapRequestsInput.value);
        };

        const rowClicked = async (row: IpproDatatable.IRow) => {
            if (archivedSelected.value) {
                await router.push({name: AdminArchivedMapRequestDetail.name, params: {id: row.id}});
            } else {
                await router.push({name: AdminMapRequestDetail.name, params: {id: row.id}});
            }

        }

        const setDepth = (depth: number, name: string) => {
            return OrganisationHelper.setDepth(depth, name);
        }

        watch(mapRequestsInput, onMapRequestsInputChanged, { immediate: true, deep: true });
        watch(selectedOrganisation, onOrganisationChanged, { immediate: true, deep: true });
        watch(selectedZone, onZoneChanged, { immediate: true, deep: true });

        onMounted(() => {
            const type = ismapRequestTypeRequested.value
                ? SearchOperationsMapRequestForOrganisationType.Requested
                : SearchOperationsMapRequestForOrganisationType.Received
            proxy.operations_SearchOperationsMapRequestForOrganisationView(props.organisation.organisationId, type)
                .then((response) => {
                    const queryTypeDefinition = {
                        orderField: KlipApi.OrderField,
                        orderDirection: KlipApi.OrderDirection,
                        searchField: KlipApi.SearchField,
                        utilityNetworkAuthorityZoneId: 'string',
                        klipOrganisationId: 'string',
                        mapRequestStatus: 'string',
                        isArchived: 'boolean',
                        startDate: 'Date',
                        endDate: 'Date',
                    };
                    organisations.value = response.result.organisations;
                    zones.value = response.result.organisationZones;
                    mapRequestsInput.value = queryFilter.mapFilterFromQuery(route, response.result.defaultSearchFilter, { searchField: 1 }, queryTypeDefinition);

                    mapRequestsColumns.value = response.result.mapRequestColumns.map((column) => {
                        return {
                            ...column, parser: cellParser,
                        };
                    });

                    queryFilter.mapColumnsFromQuery(route, mapRequestsColumns.value, KlipApi.OrderField);

                    mapRequestsSearchFields.value = response.result.searchFields;
                    mapRequestsSearchOptions.value = response.result.searchOptions;
                    if (mapRequestsInput.value.mapRequestStatus) {
                        mapRequestDefaultSearchOption.value = mapRequestsInput.value.mapRequestStatus;
                    }

                    if (route.query.offset && + route.query.offset % mapRequestsMeta.value.resultsPerPage === 0) {
                        mapRequestsMeta.value.currentPage = Math.ceil(+route.query.offset / mapRequestsMeta.value.resultsPerPage) + 1;
                    } else {
                        mapRequestsMeta.value.currentPage = 1;
                    }

                })
                .finally(() => {
                    defaultFilter.value = {
                        searchField: mapRequestsInput.value.searchField ? KlipApi.SearchField[mapRequestsInput.value.searchField] : null,
                        keyword: mapRequestsInput.value.query,
                        searchOption: mapRequestsInput.value.mapRequestStatus !== 'allstatuses' ? mapRequestsInput.value.mapRequestStatus : '',
                        date: {
                            from: mapRequestsInput.value.startDate ? [mapRequestsInput.value.startDate] : null,
                            to: mapRequestsInput.value.endDate ? [mapRequestsInput.value.endDate] : null,
                        },
                        archive: mapRequestsInput.value.isArchived ? mapRequestsInput.value.isArchived : false,
                        location: mapRequestsInput.value.zoneGeometry ? JSON.parse(mapRequestsInput.value.zoneGeometry) : null,
                    } as MapRequestSearchField;
                    archivedSelected.value = defaultFilter.value.archive;
                    viewModelLoading.value = false;
                });
        });

        return {
            viewModelLoading,
            mapRequestsSearchFields,
            mapRequestsSearchOptions,
            mapRequestDefaultSearchOption,
            mapRequestsMeta,
            mapRequestsFetching,
            mapRequestsColumns,
            mapRequests,
            defaultFilter,
            isAdmin,
            ismapRequestTypeRequested,
            moveMapRequestChecked,
            searchMaprequests,
            hasCheckedMapRequest,
            checkedMapRequests,
            moveMapRequests,
            organisations,
            zones,
            selectedOrganisation,
            selectedZone,
            search,
            moveMapRequestsChanged,
            onMoveMapRequests,
            pagerClicked,
            checkboxChanged,
            columnClicked,
            rowClicked,
            clearSearch,
            setDepth,
        };
    },
});
