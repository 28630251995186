import axios, {AxiosError, AxiosInstance} from 'axios';
import { useErrorStore } from '../../../stores/error-store';
import {UserData} from '../state/UserDataModule';
import {Logger, Impact, LogLevel, ILogEntryError, ILogEvent} from './logger';

const baseConfig = {
    baseURL: import.meta.env.VITE_BASE_URI || '',
};

const httpClient = configureAxios(axios.create(baseConfig), 'http-client.ts');
export { httpClient };

function configureAxios(axios: AxiosInstance, logFileName: string = 'proxy-client.ts') {
    axios.defaults.headers.get.Pragma = 'no-cache';
    axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    axios.interceptors.request.use(
        (config) => config,
        (error) => {
            useErrorStore().setCorrelationId(error.response?.headers.get('x-correlation-id'));
            useErrorStore().setGlobalError(error.response.status);
            return errorHandler(error, logFileName);
        },
    );
    axios.interceptors.response.use(
        (response) => response,
        (error) => errorHandler(error, logFileName),
    );
    return axios;
}

function errorHandler(error: AxiosError, logFileName: string) {
    const correlationId = error.response?.headers.get('x-correlation-id');
    if (error.code && !(error.request as XMLHttpRequest).responseURL.endsWith('api/log') && UserData.loggedOn) {
        Logger.send(<ILogEvent> {
            logEventLevel: LogLevel.Error,
            logImpact: Impact.Medium,
            fileName: logFileName,
            functionName: 'errorHandler',
            context: 'httpClient',
            detailContext: 'Error intercepted',
            id: '',
            error: <ILogEntryError> {
                message: error.request.responseText,
                baseUrl: error.config.baseURL,
                method: error.config.method,
                url: error.config.url,
                code: error.message,
                status: error.response.status
            },
            correlationId
        });
    }

    if (window && error.response && error.response.status === 401) {
        // The redirect in case of 401 can't handle urls with a hash
        // Example: when coming for the old ContactPunt application
        let url = window.location.href;

        const hashIndex = url.indexOf('#/');
        if (hashIndex !== -1) {
            url = url.replace('#/', '');
        }
        url = url.replace(window.location.origin, '');

        window.location.assign(`${window.location.origin}/auth/login?returnUrl=${url}`);
        return Promise.reject(error);
    } else {
        useErrorStore().setCorrelationId(correlationId);
        useErrorStore().setGlobalError(error.response?.status?.toString());
        return Promise.reject(error);
    }
}
