<template>
<vl-sidebar class="kl-sidebar">
    <slot></slot>
</vl-sidebar>
</template>

<script lang="ts">

import {defineComponent} from 'vue';

export default defineComponent({
    name: 'kl-sidebar',
    props: {
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
