import KlUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import { ImklTest } from '@/app/imkltest/imkltest-routes';
import { EnvelopeOfString, EnvelopeOfUploadZoneFile, IUploadZoneFile } from '@/api/klip-api.proxy';
import { IConfig } from '@/app/shared/components/kl-upload/config';
import {computed, defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
    name: 'KlUploadImkl',
    emits: ['response', 'revert'],
    components: {
        KlUpload,
    },
    props: {
        imklUploadUrl: { type: String, required: true },
        modRevertable: { type: Boolean, default: false },
        modDisabled: { type: Boolean, required: false, default: false },
        modBordered: { type: Boolean, default: false },
        isValidating: { type: Boolean, default: false },
        isImklTest: { type: Boolean, default: false },
    },
    setup(props, { emit }) {

        const success = ref<boolean>(false);
        const error = ref<boolean>(false);
        const processing = ref<boolean>(false);
        const errorMessages = ref<string[]>([]);
        const config = ref<IConfig>(null);

        const revertable = computed(() => {
            return props.modRevertable;
        });

        onMounted(() => {
            config.value = {
                url: props.imklUploadUrl,
                timeout: 600000,
                title: 'Laad uw IMKL-pakket op',
                uploadLabel: 'Bestand toevoegen',
                uploadDragText: 'Sleep het bestand naar hier om toe te voegen.',
                processingMessage: 'De gegevens worden gevalideerd. U kan even wachten of deze pagina sluiten en het resultaat hier later terugvinden.',
                guidelines: [
                    {
                        value: 'Enkel .zip-bestanden',
                    }, {
                        value: 'Maximale bestandsgrootte: 500 MB',
                    }, {
                        value: `Enkel bestanden volgens de <a href="${import.meta.env.VITE_LINKS_IMKLINFO}" target="_blank">IMKL-vereisten</a>`,
                    },
                ],
                files: {
                    amount: 1,
                    size: 500,
                    message: 'De grootte van het bestand mag maximaal 500 MB zijn.',
                    types: '.zip',
                },
            };

            if (!props.isImklTest) {
                config.value.guidelines.push({ value: `<a href="${ImklTest.path}" target="_blank">Test uw IMKL-pakket</a>` });
            }
        });


        const onFileUploaded = () => {
            processing.value = true;
        }

        const onUploadSuccess = (file: object, response: EnvelopeOfUploadZoneFile) => {
            if (props.isImklTest) {
                emit('response', response.result);
            }
        }

        const onUploadError = (file: object, message: EnvelopeOfString, xhr: object) => {
            if (xhr) {
                processing.value = false;
                error.value = true;
                if (message && message.messages) {
                    errorMessages.value = errorMessages.value.concat(message.messages);
                }
            }
        }

        const revert = () => {
            success.value = true;
            emit('revert');
        }


        return {
            success,
            error,
            processing,
            errorMessages,
            config,
            revertable,

            onFileUploaded,
            onUploadSuccess,
            onUploadError,
            revert,
        }
    }
})
