import { EnvelopeOfListOfString } from '@/api/klip-api.proxy';
import { validate, extend } from 'vee-validate';
import validations from '@/plugins/validations';
import {ValidationResult} from 'vee-validate/dist/types/types';
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';

extend('tags', validations.klipRules.tags);
extend('tag', validations.klipRules.tag);

export default defineComponent({
    name: 'KlTags',
    emits: ['input', 'add-tag', 'delete-tag'],
    props: {
        modDisabled: { type: Boolean, required: false, default: false },
    },
    setup(props, { emit, attrs }) {

        const multiselect = ref<any>(null);

        const tags = ref<string[]>(null);
        const tagSuggestions = ref<string[]>([]);


        const onAddSelect = (tag: string) => {
            // save (emit) tag selection when tag is valid
            validate(tag, 'tag').then((result: ValidationResult) => {
                if (result.valid) {
                    emit('input', tags.value);
                    emit('add-tag', tag);
                }
            });
        }

        const onRemoveSelect = (tag: string) => {
            emit('input', tags.value);
            emit('delete-tag', tag);
        }

        const onTagAdded = async (tag: string) => {
            // add to possible tags
            tagSuggestions.value.push(tag);
            // auto select
            multiselect.value.select(tag);
        }


        watch(
            tags,
            (newTags: string[], oldTags: string[]) => {
                // update validation message
                validate(newTags, 'tags').then();
            },
            { immediate: false, deep: true });


        onMounted(() => {
            tags.value = attrs.value as string[];

            // update possible tags
            useKlipApiProxy().mri_GetTags().then((response: EnvelopeOfListOfString) => {
                tagSuggestions.value = response.result;
            });
        });

        return {
            multiselect,
            tags,
            tagSuggestions,

            onAddSelect,
            onRemoveSelect,
            onTagAdded,
        }
    }
})
