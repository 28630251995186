import store from '@/store';
import { VuexModule, Mutation, Action, Module, getModule } from 'vuex-module-decorators';
import * as KlipApi from '@/api/klip-api.proxy';
import { IMapRequestListItem } from './shared/una-model';
import { useErrorStore } from '../../stores/error-store';
import {klipApiProxy} from '@/plugins/proxy-client';

export interface IMapRequestArchive {
    id: string;
    zoneId: string;
    status: string;
}

@Module({
    dynamic: true,
    store,
    name: UnaStore.namespace,
    namespaced: true,
})
export default class UnaStore extends VuexModule {
    static store = {
        namespace: 'UnaStore',
        getters: {
            mapRequestArchives: 'mapRequestArchives',
        },
    };
    public static readonly namespace = 'UnaStore';
    private toConfirm: IMapRequestListItem[] | null = null;
    private toReply: IMapRequestListItem[] | null = null;
    private toReplyTotalCount: number | null = null;
    private toConfirmTotalCount: number | null = null;
    private items: IMapRequestArchive[] = [];

    @Action
    public async fetchMapRequestToConfirm(input: KlipApi.GetMapRequestsToConfirmInput): Promise<void> {
        const mapRequest = klipApiProxy.una_GetMapRequestsToConfirm(input);
        const mapRequestTotalCount = klipApiProxy.una_GetMapRequestsToConfirmCount(input);

        return Promise.all([mapRequest, mapRequestTotalCount]).then((responses) => {
            const result = {
                mapRequests: responses[0].result.mapRequests,
                totalCount: responses[1].result,
            };
            this.mapRequestToConfirmMutation(result);
        });
    }

    @Action
    public async fetchMapRequestToReply(input: KlipApi.GetMapRequestsToReplyInput): Promise<void> {
        const mapRequest = klipApiProxy.una_GetMapRequestsToReply(input);
        const mapCount = klipApiProxy.una_GetMapRequestsToReplyCount(input);

        return Promise.all([mapRequest, mapCount]).then((responses) => {
            const result = {
                mapRequests: responses[0].result.mapRequests,
                totalCount: responses[1].result,
            };
            this.mapRequestToReplyMutation(result);
        });
    }

    @Action
    public async confirmMapRequests(mapRequests: KlipApi.ConfirmMapRequestsInput[]): Promise<void> {
        useErrorStore().setBypassError(['404']);
        await klipApiProxy.una_ConfirmMapRequests(mapRequests);
        this.confirmMapRequestsMutation(mapRequests);
    }

    @Action
    public async notInvolved(input: KlipApi.MapRequestNotInvolvedInput): Promise<void> {
        useErrorStore().setBypassError(['405']);
        await klipApiProxy.una_NotInvolved(input);
        this.notInvolvedMutation(input);
    }

    @Mutation
    public mapRequestToConfirmMutation(result: { mapRequests: KlipApi.MapRequestToConfirmListItem[]; totalCount: number; }) {
        const toConFirmMapRequests = result.mapRequests.map((mapRequestResponse) => ({
            confirmationDate: mapRequestResponse.confirmationDate,
            dateReceived: mapRequestResponse.dateReceived,
            isOverdue: mapRequestResponse.isOverdue,
            mapRequestId: mapRequestResponse.mapRequestId,
            reference: mapRequestResponse.reference,
            unaZoneId: mapRequestResponse.unaZoneId,
            zoneName: mapRequestResponse.zoneName,
            startDate: mapRequestResponse.startDate,
            mapRequestType: mapRequestResponse.mapRequestType,
            error: mapRequestResponse.isOverdue,
            organisation: mapRequestResponse.organisation,
            checked: true,
        }));
        this.toConfirm = toConFirmMapRequests;
        this.toConfirmTotalCount = result.totalCount;
    }

    @Mutation
    public mapRequestToReplyMutation(result: { mapRequests: KlipApi.MapRequestToReplyListItem[]; totalCount: number; }) {
        const mapRequests = result.mapRequests.map((mapRequestResponse) => ({
            isOverdue: false,
            dateReceived: mapRequestResponse.dateReceived,
            mapRequestId: mapRequestResponse.mapRequestId,
            reference: mapRequestResponse.reference,
            unaZoneId: mapRequestResponse.unaZoneId,
            zoneName: mapRequestResponse.zoneName,
            startDate: mapRequestResponse.startDate,
            mapRequestType: mapRequestResponse.mapRequestType,
            organisation: mapRequestResponse.organisation,
        }));
        this.toReply = mapRequests;
        this.toReplyTotalCount = result.totalCount;
    }

    @Mutation
    public notInvolvedMutation(input: KlipApi.MapRequestNotInvolvedInput) {
        if (this.toReply) {
            this.toReply = this.toReply
                .filter((item) => item.mapRequestId !== input.mapRequestId && item.unaZoneId !== input.zoneId);
            this.toReplyTotalCount = this.toReplyTotalCount - 1;
        }
    }

    @Mutation
    public confirmMapRequestsMutation(confirmedMapRequests: KlipApi.ConfirmMapRequestsInput[]) {
        if (!this.toConfirm) {
            return;
        }

        const confirmedRequests = this.toConfirm
            .filter((mapRequest) => {
                const index = confirmedMapRequests
                    .findIndex((x) => mapRequest.mapRequestId === x.mapRequestId && mapRequest.unaZoneId === x.zoneId);
                return index >= 0;
            });

        this.toConfirm = this.toConfirm
            .filter((mapRequest) => {
                const index = confirmedRequests
                    .findIndex((x) => mapRequest.mapRequestId === x.mapRequestId && mapRequest.unaZoneId === x.unaZoneId);
                return index < 0;
            });
        this.toConfirm.forEach((x) => x.checked = false);
        this.toConfirmTotalCount = this.toConfirmTotalCount - confirmedRequests.length;

        this.toReply = this.toReply.concat(confirmedRequests);
        this.toReplyTotalCount = this.toReplyTotalCount + confirmedRequests.length;
    }

    @Mutation
    public MapRequestArchiveStatusMutation(mapRequestArchive: IMapRequestArchive) {
        if (this.items.filter((item) => item.id === mapRequestArchive.id && item.zoneId === mapRequestArchive.zoneId).length) {
            this.items = this.items.map((item) => (item.id === mapRequestArchive.id && item.zoneId === mapRequestArchive.zoneId) ? mapRequestArchive : item);
        } else {
            this.items.push(mapRequestArchive);
        }
    }

    get mapRequestsToConfirm(): IMapRequestListItem[] {
        return this.toConfirm;
    }

    get mapRequestsToConfirmTotalCount(): number {
        return this.toConfirmTotalCount;
    }

    get mapRequestsToReply(): IMapRequestListItem[] {
        return this.toReply;
    }

    get mapRequestsToReplyTotalCount(): number {
        return this.toReplyTotalCount;
    }

    get mapRequestArchives(): IMapRequestArchive[] {
        return this.items;
    }
}

export const UnaDataService = getModule(UnaStore);
